import React from 'react';
export const FinalRemarks = ({ data, version, showFinalRemarks }) => {
    let finalData = data.replace(/\*\*/g, '');
    console.log(finalData);
    const individualSkills = finalData
        .split('\n\n')
        .map((content) => ({ skillName: content.split(':')[0].trim(), content }));
    console.log(individualSkills);
    return (
        <div className="detailed-feedback-list break-stop">
            {version > 4 && (
                <div className="skills-div">
                    <span className="soft-skills-span">Final Remarks</span>
                </div>
            )}
            <div className="soft-skill-wrapperv2">
                {version < 5 && (
                    <>
                        <div className="font-style-header-soft-skill soft-skill-header-600">Final Remarks</div>
                        <div className="soft-skill-header-outer">
                            <div className="soft-skill-header-type-1 font-style-header-soft-skill">Final Remarks</div>
                        </div>
                    </>
                )}
                <div className="soft-skill-content">
                    {individualSkills.map((skill, index) =>
                        version < 7 || (version >= 7 && showFinalRemarks[skill?.skillName]) ? (
                            <pre className="font-style-soft-skill" style={{ whiteSpace: 'pre-wrap' }}>
                                <code
                                    dangerouslySetInnerHTML={{
                                        __html: `${skill.content.replace('**', '')}`,
                                    }}
                                />
                            </pre>
                        ) : (
                            <></>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

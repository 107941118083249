import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { ToastManager } from '../../../Toast/ToastManager';
import ResumeInput from '../portal/ResumeInput';
import Button from '../../../commonComponent/Button';
import ContactInput from '../../../commonComponent/ContactInput';
import Input from '../../../commonComponent/Input';
import { isMobileOrTabletDevice } from '../../../../utils/helper';

const CandidateDetails = ({ form, setForm, handleSubmit }) => {
    // State to control the disabled state of the button
    const [isDisabled, setIsDisabled] = useState(true);
    const submitLoading = useSelector((state) => state.Interview.verifyLoading);

    // Disable the button if required fields are not filled
    useEffect(() => {
        const { name, email, phone } = form?.candidate || {};
        setIsDisabled(!(name && email && phone && form?.resume));
    }, [form]);

    // Validate and sanitize email input
    const mailChecker = (value) => value.replace(/[^a-zA-Z0-9_\-@.+]/g, '');

    // Validate and sanitize name input
    const nameChecker = (value) => value.replace(/[^A-Za-z\s.]/g, '');

    // Check if the uploaded file has valid format
    const checkFileValidation = (file) => {
        const validTypes = [
            'application/pdf',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/msword',
        ];
        return validTypes.includes(file.type);
    };

    // Handle file change for resume input
    const handleFileChange = (e) => {
        const { files, name } = e.target;
    
        if (!files?.length) return;
    
        const file = files[0];
    
        // Step 1: Validate file type and format using checkFileValidation
        if (!checkFileValidation(file)) {
            e.target.value = null; // Reset file input
            ToastManager.addToast({
                title: 'Invalid file format. Only DOC, DOCX, and PDF files are allowed.',
            });
            return;
        }
    
        // Step 2: Validate file size
        if (file.size > 5 * 1024 * 1024) {
            e.target.value = null; // Reset file input
            ToastManager.addToast({
                title: 'The file size exceeds the 5 MB limit.',
            });
            return;
        }
    
        // If both validations pass, update the form state
        setForm((prev) => ({
            ...prev,
            [name]: file,
        }));
    };;

    // Handle form input change
    const handleInputChange = (name, checker) => (e) => {
        setForm((prev) => ({
            ...prev,
            candidate: {
                ...prev?.candidate,
                [name]: checker(e.target.value),
            },
        }));
    };

    // Handle phone input change
    const handlePhoneNumberChange = (newNumber, code) => {
        setForm((prev) => ({
            ...prev,
            candidate: {
                ...prev?.candidate,
                phone: `+${code}${newNumber}`,
            },
        }));
    };

    const Loader = memo(() => {
        return (
            <div className="wrapper-4">
                <div className="loader"></div>
            </div>
        );
    });

    return (
        <div className="instruction-container-box">
            {submitLoading && <Loader />}
            <div className={`interview-candidate-instruction${isMobileOrTabletDevice() ? ' otp-div-center' : ''}`}>
                <div className="instruction-title">Let's get started!</div>
                <p className="instruction-subtitle">Kindly enter your details to proceed</p>
                <div className="candidate-fields">
                    <div className="mb-2 w-100">
                        <Input
                            label="Your Name"
                            isRequired
                            className="candidate-input"
                            name="name"
                            type="text"
                            value={form?.candidate?.name || ''}
                            onChange={handleInputChange('name', nameChecker)}
                            placeholder="Enter your name"
                            borderNone
                        />
                    </div>
                    <div className="w-100">
                        <ResumeInput form={form} handleFileChange={handleFileChange} />
                    </div>
                    <div className="mb-2 w-100">
                        <Input
                            label="Email Id"
                            isRequired
                            className="candidate-input"
                            name="email"
                            type="text"
                            value={form?.candidate?.email || ''}
                            onChange={handleInputChange('email', mailChecker)}
                            placeholder="Enter your email"
                            borderNone
                        />
                    </div>
                    <div className="mb-2 w-100">
                        <ContactInput
                            label="Whatsapp Number"
                            inputClass="candidate-input"
                            formData={`${form?.candidate?.countryCode || ''}${form?.candidate?.phone || ''}`}
                            setPhoneNumber={handlePhoneNumberChange}
                            placeholder="Enter whatsapp number"
                            isRequired
                            borderNone
                        />
                    </div>
                    <Button
                        text="Next"
                        disabled={isDisabled}
                        className="candidate-btn"
                        onClick={debounce(() => handleSubmit(), 300)}
                    />
                </div>
            </div>
        </div>
    );
};

export default CandidateDetails;

import React, { useEffect, useRef, useState } from 'react';
import { useScreenShare } from './ScreenshareContext';
import { useDispatch } from 'react-redux';
import { screenShareVid } from '../../../../redux/action';
import Button from '../../../commonComponent/Button';
import link from '../../../../assets/images/link.svg';
import tick from '../../../../assets/images/tick.svg';
import Modal from '../../../commonComponent/Modal/Modal';
import faq from '../../../../assets/images/faq.svg';

const ScreenShare = ({ isScreenShared, setIsScreenShared }) => {
    const { stream, setStream } = useScreenShare();
    const videoRef = useRef(null);
    const [error, setError] = useState('');
    const [showFaq, setShowFaq] = useState(false);

    const dispatch = useDispatch();

    // useEffect(() => {
    //     dispatch(screenShareVidRef(videoRef));
    // }, [videoRef]);

    const startScreenShare = async () => {
        try {
            // Request screen sharing
            const newStream = await navigator.mediaDevices.getDisplayMedia({
                video: {
                    displaySurface: 'monitor',
                },
            });

            const videoTrack = newStream.getVideoTracks()[0];
            const settings = videoTrack.getSettings();

            if (settings.displaySurface !== 'monitor') {
                dispatch(screenShareVid(false));
                setIsScreenShared(false);
                setError('Please select the entire screen to share.');
                videoTrack.stop();
            } else {
                dispatch(screenShareVid(true));
                setIsScreenShared(true);
                setError('');
                setStream(newStream);

                videoTrack.onended = () => {
                    dispatch(screenShareVid(false));
                    setIsScreenShared(false);
                    setStream(null);
                    setError('Screen sharing has been stopped.');
                };
            }
        } catch (err) {
            console.error('Error starting screen share:', err);
            setError('Screen sharing failed. Please try again.');
        }
    };

    useEffect(() => {
        // Play the stream if it exists
        if (stream && videoRef.current) {
            videoRef.current.srcObject = stream;
            videoRef.current.play();
        }
    }, [stream]);

    const faqContent = () => {
        return (
            <div
                className="d-flex flex-column w-100 align-items-center justify-content-center mb-4"
                style={{ color: 'white', fontSize: 14 }}
            >
                <div className="w-100 text-center mb-3">
                    To proceed with the test, please ensure you're sharing your Entire Screen as required.
                </div>
                <div className="w-100 d-flex flex-column">
                    <div>Quick Fixes:</div>
                    <div>1. Restart the screen share using the Restart Screen Capture button on permission page.</div>
                    <div>2. In the screen share popup, select the Entire Screen tab and click the Share button.</div>
                    <div>3. Ensure you are using a compatible browser such as Google Chrome or Microsoft Edge.</div>
                </div>
            </div>
        );
    };

    return (
        <div>
            <Modal
                isOpen={showFaq}
                headerText={'Incorrect screen share mode'}
                imgSrc={faq}
                buttonText="Okay"
                handleClick={() => setShowFaq(false)}
                Content={faqContent}
                headerStyle={'faqHeader f-bol'}
            />
            <div className="d-flex align-items-center mb-4">
                <div className="acknowledge_validation_text my-auto w-50 text-start">Screen share</div>
                <div className="acknowledge_validation_text my-auto w-50 d-flex justify-content-center">
                    {isScreenShared ? (
                        <img src={tick} alt="" />
                    ) : (
                        <Button
                            type="submit"
                            onClick={startScreenShare}
                            className="screen-share-button"
                            text={'Start Screen Capture'}
                        ></Button>
                    )}
                </div>
            </div>
            <div className="w-100 f-12" style={{ color: error ? '#ff0000' : 'white' }}>
                {error ? (
                    <div>
                        Incorrect screen share mode. Please share your entire screen to start the interview.{' '}
                        <span style={{ color: '#12B6BC', cursor: 'pointer' }} onClick={() => setShowFaq(true)}>
                            Quick Fixes <img src={link} alt="" />
                        </span>
                    </div>
                ) : (
                    '*Please share your entire screen to continue with the interview.'
                )}
            </div>
        </div>
    );
};

export default ScreenShare;

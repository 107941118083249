import React, { useEffect, useState } from 'react';
import { formatTime } from '../../../Feedback/components/Helpers';
import SubmitIcon from '../../../../assets/images/submit-icon.svg';
import SidebarQuestion from './SidebarQuestion';
import Button from '../../../commonComponent/Button';
import { getAssetSrc } from '../../../../utils/helper';
import { ASSET_NAMES } from '../../../../common/constants';
const Question = ({
    questionInfo,
    isSpeaking,
    totalQuestion,
    isSkipEnabled,
    prepareTime,
    answerTime,
    skipTimer,
    handleEndCall,
    candidatePeer,
    isButtonDisabled,
    handleNextQuestionClick,
    isNextButtonEnabled,
    question,
    position,
    setPosition,
    codeBlock,
    isQuestionTypeMcq,
    mcqForm,
    selectedOption,
    setSelectedOption,
    handleNextMcqClick,
    stopForProceed,
    setStopForProceed,
}) => {
    const [stopTime, setStopTime] = useState(120);
    const skipButtonEnabled = !isSpeaking && isSkipEnabled;
    const submitAnswerButtonEnabled = !isSpeaking && !isButtonDisabled && isNextButtonEnabled;
    const renderText = (label, imgSrc) => (
        <>
            <span>{label}</span>
            {imgSrc && <img className="btn-icon" src={imgSrc} alt="" />}
        </>
    );

    useEffect(() => {
        if (!isSpeaking && isQuestionTypeMcq && stopForProceed) {
            let stopTimer;
            const ansTimerFunc = () => {
                if (stopTime > 0) {
                    setStopTime(stopTime - 1);
                } else {
                    setStopForProceed(false);
                    clearInterval(stopTimer);
                }
            };
            stopTimer = setInterval(async () => {
                ansTimerFunc();
            }, 1000);
            return () => {
                clearInterval(stopTimer);
            };
        }
    }, [stopForProceed, isSpeaking, isQuestionTypeMcq, stopTime]);

    return (
        <div className={isQuestionTypeMcq ? 'sidebar-container-mcq' : 'sidebar-container'}>
            <div className="question-timer-container d-flex justify-content-between">
                <div className="d-flex align-items-center">
                    {totalQuestion >= questionInfo?.questionNumber && (
                        <Button
                            text={renderText('Skip Prep Time', getAssetSrc(ASSET_NAMES.SKIP_FORWARD))}
                            className={`common-btn timer-btn${skipButtonEnabled ? '' : ' disabled'}`}
                            onClick={skipTimer}
                            disabled={!skipButtonEnabled}
                        />
                    )}
                    <div className={`time-countdown ${prepareTime < 1 ? 'visibility-hidden' : ''}`}>
                        <span>{prepareTime ?? '0'}</span>
                        <span className="time-unit">{prepareTime > 1 ? 'secs' : 'sec'}</span>
                    </div>
                </div>
                {stopForProceed ? (
                    <div className="d-flex align-items-center">
                        <Button
                            text={renderText('Proceed', getAssetSrc(ASSET_NAMES.NEXT_QUESTION))}
                            className="common-btn timer-btn"
                            onClick={() => setStopForProceed(false)}
                            disabled={isSpeaking}
                        />
                        <div className="next-ques-time-countdown">{formatTime(stopTime) ?? '00:00'}</div>
                    </div>
                ) : (
                    <div className="d-flex align-items-center">
                        {totalQuestion > questionInfo?.questionNumber ? (
                            <Button
                                text={renderText('Next Question', getAssetSrc(ASSET_NAMES.NEXT_QUESTION))}
                                className={`common-btn timer-btn${!submitAnswerButtonEnabled ? ' disabled' : ''}`}
                                onClick={isQuestionTypeMcq ? handleNextMcqClick : handleNextQuestionClick}
                                disabled={!submitAnswerButtonEnabled}
                            />
                        ) : totalQuestion === questionInfo?.questionNumber ? (
                            <Button
                                text={renderText('Submit Answer', SubmitIcon)}
                                className={`common-btn timer-btn${!submitAnswerButtonEnabled ? ' disabled' : ''}`}
                                onClick={isQuestionTypeMcq ? handleNextMcqClick : handleNextQuestionClick}
                                disabled={!submitAnswerButtonEnabled}
                            />
                        ) : (
                            <Button
                                text={renderText('Submit', SubmitIcon)}
                                className="common-btn timer-btn"
                                onClick={() => handleEndCall()}
                            />
                        )}
                        <div className="next-ques-time-countdown">{formatTime(answerTime) ?? '00:00'}</div>
                    </div>
                )}
            </div>
            <SidebarQuestion
                isSpeaking={isSpeaking}
                question={question}
                questionType={questionInfo?.questionType}
                candidatePeer={candidatePeer}
                position={position}
                setPosition={setPosition}
                codeBlock={codeBlock}
                isQuestionTypeMcq={isQuestionTypeMcq}
                mcqForm={mcqForm}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                stopForProceed={stopForProceed}
            />
        </div>
    );
};

export default Question;

import {
    CANDIDATE_CALL_END,
    CANDIDATE_CALL_END_FAILURE,
    CANDIDATE_CALL_END_SUCCESS,
    CANDIDATE_CALL_START,
    CANDIDATE_CALL_START_FAILURE,
    CANDIDATE_CALL_START_SUCCESS,
    CONTACT_ADMIN,
    CONTACT_ADMIN_FAILURE,
    CONTACT_ADMIN_SUCCESS,
    CREATE_AND_UPDATE_SCREENSHOT,
    CREATE_AND_UPDATE_SCREENSHOT_FAILURE,
    CREATE_AND_UPDATE_SCREENSHOT_SUCCESS,
    CREATE_CODE_SUBMISSION,
    CREATE_CODE_SUBMISSION_FAILURE,
    CREATE_CODE_SUBMISSION_SUCCESS,
    FETCH_SCREENSHOT_IMAGE,
    FETCH_SCREENSHOT_IMAGE_FAILURE,
    FETCH_SCREENSHOT_IMAGE_SUCCESS,
    FULL_FACE_DETECTION,
    FULL_FACE_DETECTION_FAILURE,
    FULL_FACE_DETECTION_NEW,
    FULL_FACE_DETECTION_NEW_FAILURE,
    FULL_FACE_DETECTION_NEW_SUCCESS,
    FULL_FACE_DETECTION_SUCCESS,
    GET_INTERVIEW,
    GET_INTERVIEW_FAILURE,
    GET_INTERVIEW_SUCCESS,
    GET_INTERVIEW_TOKEN,
    GET_INTERVIEW_TOKEN_FAILURE,
    GET_INTERVIEW_TOKEN_SUCCESS,
    GET_QUESTION,
    GET_QUESTION_FAILURE,
    GET_QUESTION_NUMBER,
    GET_QUESTION_NUMBER_FAILURE,
    GET_QUESTION_NUMBER_SUCCESS,
    GET_QUESTION_SUCCESS,
    INTERVIEW_FEEDBACK_STATUS,
    INTERVIEW_FEEDBACK_STATUS_FAILURE,
    INTERVIEW_FEEDBACK_STATUS_SUCCESS,
    LOOKING_FORWARD,
    LOOKING_FORWARD_FAILURE,
    LOOKING_FORWARD_SUCCESS,
    MOVEMENT,
    MOVEMENT_FAILURE,
    MOVEMENT_SUCCESS,
    NOTIFICATION_ERROR,
    NOTIFICATION_ERROR_FAILURE,
    NOTIFICATION_ERROR_SUCCESS,
    POST_CANDIDATE,
    POST_CANDIDATE_FAILURE,
    POST_CANDIDATE_SUCCESS,
    POST_INTERVIEW_FEEDBACK,
    POST_INTERVIEW_FEEDBACK_FAILURE,
    POST_INTERVIEW_FEEDBACK_SUCCESS,
    POST_INTERVIEW_STATUS,
    POST_INTERVIEW_STATUS_FAILURE,
    POST_INTERVIEW_STATUS_SUCCESS,
    PRACTICE_QUESTION,
    PRACTICE_QUESTION_FAILURE,
    PRACTICE_QUESTION_SUCCESS,
    PRACTICE_QUESTION_UPDATE,
    PRACTICE_QUESTION_UPDATE_FAILURE,
    PRACTICE_QUESTION_UPDATE_SUCCESS,
    REMOVE_SCREENSHOT_IMAGE,
    RESEND_OTP_REQUEST,
    RESEND_OTP_REQUEST_FAILURE,
    RESEND_OTP_REQUEST_SUCCESS,
    RESET_CODE_SUBMISSION_OUTPUT,
    SCREEN_SHARE_VID,
    SUBMIT_CODING_QUESTION,
    SUBMIT_CODING_QUESTION_FAILURE,
    SUBMIT_CODING_QUESTION_SUCCESS,
    SUBMIT_MCQ,
    SUBMIT_MCQ_FAILURE,
    SUBMIT_MCQ_SUCCESS,
    SUBMIT_TRANSCRIBE,
    SUBMIT_TRANSCRIBE_FAILURE,
    SUBMIT_TRANSCRIBE_SUCCESS,
    SWITCH_TAB,
    SWITCH_TAB_FAILURE,
    SWITCH_TAB_SUCCESS,
    UPLOAD_VIDEO,
    VERIFY_CANDIDATE_KEY,
    VERIFY_CANDIDATE_KEY_FAILURE,
    VERIFY_CANDIDATE_KEY_SUCCESS,
    VERIFY_ROOM,
    VERIFY_ROOM_FAILURE,
    VERIFY_ROOM_SUCCESS,
} from './types';

export const practiceQuestion = (payload) => ({
    type: PRACTICE_QUESTION,
    payload,
});
export const practiceQuestionSuccess = () => ({
    type: PRACTICE_QUESTION_SUCCESS,
});
export const practiceQuestionFailure = () => ({
    type: PRACTICE_QUESTION_FAILURE,
});

export const practiceQuestionUpdate = (payload) => ({
    type: PRACTICE_QUESTION_UPDATE,
    payload,
});
export const practiceQuestionUpdateSuccess = () => ({
    type: PRACTICE_QUESTION_UPDATE_SUCCESS,
});
export const practiceQuestionUpdateFailure = () => ({
    type: PRACTICE_QUESTION_UPDATE_FAILURE,
});

export const notificationError = (payload) => ({
    type: NOTIFICATION_ERROR,
    payload,
});
export const notificationErrorSuccess = () => ({
    type: NOTIFICATION_ERROR_SUCCESS,
});
export const notificationErrorFailure = () => ({
    type: NOTIFICATION_ERROR_FAILURE,
});
export const submitTranscribe = (payload) => ({
    type: SUBMIT_TRANSCRIBE,
    payload,
});
export const submitTranscribeSuccess = () => ({
    type: SUBMIT_TRANSCRIBE_SUCCESS,
});
export const submitTranscribeFailure = () => ({
    type: SUBMIT_TRANSCRIBE_FAILURE,
});
export const interviewFeedbackStatus = (payload) => ({
    type: INTERVIEW_FEEDBACK_STATUS,
    payload,
});
export const interviewFeedbackStatusSuccess = (payload) => ({
    type: INTERVIEW_FEEDBACK_STATUS_SUCCESS,
    payload,
});
export const interviewFeedbackStatusFailure = () => ({
    type: INTERVIEW_FEEDBACK_STATUS_FAILURE,
});
export const movementRequest = (payload) => ({
    type: MOVEMENT,
    payload,
});
export const movementSuccess = () => ({
    type: MOVEMENT_SUCCESS,
});
export const movementFailure = () => ({
    type: MOVEMENT_FAILURE,
});

export const switchTab = (payload) => ({
    type: SWITCH_TAB,
    payload,
});
export const switchTabSuccess = () => ({
    type: SWITCH_TAB_SUCCESS,
});
export const switchTabFailure = () => ({
    type: SWITCH_TAB_FAILURE,
});

export const createAndUpdateScreenshots = (payload) => ({
    type: CREATE_AND_UPDATE_SCREENSHOT,
    payload,
});
export const createAndUpdateScreenshotsSuccess = (payload) => ({
    type: CREATE_AND_UPDATE_SCREENSHOT_SUCCESS,
    payload,
});
export const createAndUpdateScreenshotsFailure = () => ({
    type: CREATE_AND_UPDATE_SCREENSHOT_FAILURE,
});

export const verifyCandidateKey = (payload) => ({
    type: VERIFY_CANDIDATE_KEY,
    payload,
});
export const verifyCandidateKeySuccess = (payload) => ({
    type: VERIFY_CANDIDATE_KEY_SUCCESS,
    payload,
});
export const verifyCandidateKeyFailure = () => ({
    type: VERIFY_CANDIDATE_KEY_FAILURE,
});

export const verifyRoom = (payload) => ({
    type: VERIFY_ROOM,
    payload,
});
export const verifyRoomSuccess = (payload) => ({
    type: VERIFY_ROOM_SUCCESS,
    payload,
});
export const verifyRoomFailure = () => ({
    type: VERIFY_ROOM_FAILURE,
});

export const candidateCallEnd = (payload) => ({
    type: CANDIDATE_CALL_END,
    payload,
});
export const candidateCallEndSuccess = (payload) => ({
    type: CANDIDATE_CALL_END_SUCCESS,
    payload,
});
export const candidateCallEndFailure = () => ({
    type: CANDIDATE_CALL_END_FAILURE,
});

export const candidateCallStart = (payload) => ({
    type: CANDIDATE_CALL_START,
    payload,
});
export const candidateCallStartSuccess = (payload) => ({
    type: CANDIDATE_CALL_START_SUCCESS,
    payload,
});
export const candidateCallStartFailure = () => ({
    type: CANDIDATE_CALL_START_FAILURE,
});

export const removeScreenShotImage = () => ({
    type: REMOVE_SCREENSHOT_IMAGE,
});
export const fetchScreenShotImage = (payload) => ({
    type: FETCH_SCREENSHOT_IMAGE,
    payload,
});
export const fetchScreenShotImageSuccess = (payload) => ({
    type: FETCH_SCREENSHOT_IMAGE_SUCCESS,
    payload,
});
export const fetchScreenShotImageFailure = () => ({
    type: FETCH_SCREENSHOT_IMAGE_FAILURE,
});
export const getInterviewToken = (payload) => ({
    type: GET_INTERVIEW_TOKEN,
    payload,
});
export const getInterviewTokenSuccess = (payload) => ({
    type: GET_INTERVIEW_TOKEN_SUCCESS,
    payload,
});
export const getInterviewTokenFailure = () => ({
    type: GET_INTERVIEW_TOKEN_FAILURE,
});

export const resendOtpRequest = (payload) => ({
    type: RESEND_OTP_REQUEST,
    payload,
});
export const resendOtpRequestSuccess = (payload) => ({
    type: RESEND_OTP_REQUEST_SUCCESS,
    payload,
});
export const resendOtpRequestFailure = () => ({
    type: RESEND_OTP_REQUEST_FAILURE,
});

export const getInterview = (payload) => ({
    type: GET_INTERVIEW,
    payload,
});
export const getInterviewSuccess = (payload) => ({
    type: GET_INTERVIEW_SUCCESS,
    payload,
});
export const getInterviewFailure = () => ({
    type: GET_INTERVIEW_FAILURE,
});

export const postInterviewStatus = (payload) => ({
    type: POST_INTERVIEW_STATUS,
    payload,
});
export const postInterviewStatusSuccess = () => ({
    type: POST_INTERVIEW_STATUS_SUCCESS,
});
export const postInterviewStatusFailure = () => ({
    type: POST_INTERVIEW_STATUS_FAILURE,
});

export const getQuestionNumber = (payload) => ({
    type: GET_QUESTION_NUMBER,
    payload,
});
export const getQuestionNumberSuccess = (payload) => ({
    type: GET_QUESTION_NUMBER_SUCCESS,
    payload,
});
export const getQuestionNumberFailure = () => ({
    type: GET_QUESTION_NUMBER_FAILURE,
});

export const getQuestion = (payload) => ({
    type: GET_QUESTION,
    payload,
});
export const getQuestionSuccess = (payload) => ({
    type: GET_QUESTION_SUCCESS,
    payload,
});
export const getQuestionFailure = () => ({
    type: GET_QUESTION_FAILURE,
});

export const postInterviewFeedback = (payload) => ({
    type: POST_INTERVIEW_FEEDBACK,
    payload,
});
export const postInterviewFeedbackSuccess = () => ({
    type: POST_INTERVIEW_FEEDBACK_SUCCESS,
});
export const postInterviewFeedbackFailure = () => ({
    type: POST_INTERVIEW_FEEDBACK_FAILURE,
});

export const postCandidate = (payload) => ({
    type: POST_CANDIDATE,
    payload,
});
export const postCandidateSuccess = () => ({
    type: POST_CANDIDATE_SUCCESS,
});
export const postCandidateFailure = () => ({
    type: POST_CANDIDATE_FAILURE,
});

export const contactAdmin = (payload) => ({
    type: CONTACT_ADMIN,
    payload,
});
export const contactAdminSuccess = () => ({
    type: CONTACT_ADMIN_SUCCESS,
});
export const contactAdminFailure = () => ({
    type: CONTACT_ADMIN_FAILURE,
});
export const fullFaceDetectionNew = (payload) => ({
    type: FULL_FACE_DETECTION_NEW,
    payload,
});
export const fullFaceDetectionNewSuccess = (payload) => ({
    type: FULL_FACE_DETECTION_NEW_SUCCESS,
    payload,
});
export const fullFaceDetectionNewFailure = (payload) => ({
    type: FULL_FACE_DETECTION_NEW_FAILURE,
    payload,
});
export const createCodeSubmission = (payload) => {
    return {
        type: CREATE_CODE_SUBMISSION,
        payload,
    };
};
export const createCodeSubmissionSuccess = (payload) => ({
    type: CREATE_CODE_SUBMISSION_SUCCESS,
    payload,
});
export const createCodeSubmissionFailure = () => ({
    type: CREATE_CODE_SUBMISSION_FAILURE,
});
export const resetCodeSubmissionOutput = () => ({
    type: RESET_CODE_SUBMISSION_OUTPUT,
});
export const submitCodingQuestion = (payload) => ({
    type: SUBMIT_CODING_QUESTION,
    payload,
});
export const submitCodingQuestionSuccess = () => ({
    type: SUBMIT_CODING_QUESTION_SUCCESS,
});
export const submitCodingQuestionFailure = () => ({
    type: SUBMIT_CODING_QUESTION_FAILURE,
});

export const lookingForward = (payload) => ({
    type: LOOKING_FORWARD,
    payload,
});
export const lookingForwardSuccess = (payload) => ({
    type: LOOKING_FORWARD_SUCCESS,
    payload,
});
export const lookingForwardFailure = (payload) => ({
    type: LOOKING_FORWARD_FAILURE,
    payload,
});

export const uploadVideo = (payload) => ({
    type: UPLOAD_VIDEO,
    payload,
});
export const fullFaceDetection = (payload) => ({
    type: FULL_FACE_DETECTION,
    payload,
});
export const fullFaceDetectionSuccess = (payload) => ({
    type: FULL_FACE_DETECTION_SUCCESS,
    payload,
});
export const fullFaceDetectionFailure = (payload) => ({
    type: FULL_FACE_DETECTION_FAILURE,
    payload,
});

export const submitMcq = (payload) => ({
    type: SUBMIT_MCQ,
    payload,
});
export const submitMcqSuccess = (payload) => ({
    type: SUBMIT_MCQ_SUCCESS,
    payload,
});
export const submitMcqFailure = (payload) => ({
    type: SUBMIT_MCQ_FAILURE,
    payload,
});
export const screenShareVid = (payload) => ({
    type: SCREEN_SHARE_VID,
    payload,
});

import React from 'react';
import { formatTime, getStatusV } from './components/Helpers';
export const ScreeningQuestionsv2 = ({ data }) => {
    return (
        <div className="screening-ques-wrapper break-stop">
            <div className="skills-div">
                <span className="soft-skills-span">Screening Questions</span>
            </div>
            <div className="ques-ans-wrapper">
                {data &&
                    data.length > 0 &&
                    data.map((itm, index) => (
                        <div className="break-stop" key={index}>
                            <div className="question-container">
                                <pre className="question-sub-panel" style={{ whiteSpace: 'pre-wrap', margin: '0px' }}>
                                    <code
                                        dangerouslySetInnerHTML={{
                                            __html: `${index + 1}. ${itm?.question}`,
                                        }}
                                    />
                                </pre>
                                <div className="question-sub-panel time-taken">
                                    Time Taken: {formatTime(itm?.timeTaken)}
                                </div>
                                <div
                                    className={`graph-descp-comment graph-status question-sub-panel ${
                                        getStatusV(itm.rating)?.color
                                    }`}
                                >
                                    ({getStatusV(itm.rating)?.title.substring(0, 21)})
                                </div>
                            </div>
                            <pre className="ans font-style-soft-skill" style={{ whiteSpace: 'pre-wrap' }}>
                                <code
                                    dangerouslySetInnerHTML={{
                                        __html: `${itm?.answer !== '' ? itm?.answer : "The candidate didn't answer."}`,
                                    }}
                                />
                            </pre>
                        </div>
                    ))}
            </div>
        </div>
    );
};

import { isMobileOrTabletDevice } from '../../../utils/helper';
import { VIDEO_ID } from './constants';
export const compressImage = async ({ canvasRef, onFailure, sendCompressedFrame }) => {
    const removeImagePrefix = (dataURL) => {
        // Remove the data URL prefix to obtain only the base64-encoded data
        const commaIndex = dataURL.indexOf(',');
        return commaIndex !== -1 ? dataURL.substring(commaIndex + 1) : dataURL;
    };

    const videoElement = document.getElementById(VIDEO_ID);
    if (canvasRef.current && videoElement) {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        try {
            let targetWidth = 320;
            let targetHeight = 240;
            if (isMobileOrTabletDevice()) {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
                const videoTrack = stream.getVideoTracks()[0];
                const { width, height } = videoTrack.getSettings();
                targetWidth = width;
                targetHeight = height;
            }

            // Set canvas dimensions
            canvas.width = targetWidth;
            canvas.height = targetHeight;

            // Draw the video frame onto the canvas at specified dimensions
            context.drawImage(videoElement, 0, 0, targetWidth, targetHeight);

            // Convert the canvas content to a PNG base64 string without prefix
            const compressedFrame = canvas.toDataURL('image/jpeg', 0.8); // PNG format does not support quality adjustment
            const base64PNG = removeImagePrefix(compressedFrame);

            // Send or return the base64-encoded PNG string
            if (sendCompressedFrame) {
                sendCompressedFrame(base64PNG);
            }

            return base64PNG;
        } catch (error) {
            console.error('Error processing video stream:', error);
            onFailure();
        }
    } else {
        onFailure();
    }
};

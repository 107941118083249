import React from 'react';
import { Flex, styled, Text } from '@100mslive/react-ui';
import { getAssetSrc } from '../../../../utils/helper';
import { ASSET_NAMES } from '../../../../common/constants';
import { useSelector } from 'react-redux';
const LogoImg = styled('img', {
    p: '$2',
});

export default function MobileDeviceUnsupported() {
    const whiteLabelLogos = useSelector((state) => state.Interview.whiteLabelLogos);
    const fetchingWhiteLabelStatus = useSelector((state) => state.Interview.fetchingWhiteLabelStatus);
    return (
        <Flex direction="column" css={{ width: '100vw', height: '100vh', bg: '#191924' }}>
            <Flex
                justify="center"
                direction="column"
                align="center"
                css={{ bg: '#191924', flex: '1 1 0', position: 'relative', padding: '0px 25px' }}
            >
                {!fetchingWhiteLabelStatus && (
                    <LogoImg
                        src={whiteLabelLogos?.whiteLogo || getAssetSrc(ASSET_NAMES.INCRUITER_LOGO)}
                        alt="InCruiter Logo"
                        width={150}
                        height={61.5}
                        css={{ top: '5%', position: 'absolute', objectFit: 'contain' }}
                    />
                )}
                <LogoImg
                    src={getAssetSrc(ASSET_NAMES.NOT_SUPPORTED)}
                    alt="InCruiter Logo"
                    width={window.innerWidth > 992 ? 615 : 177}
                    height={window.innerWidth > 992 ? 354 : 'auto'}
                />
                <div className="acknowledge_title_line mt-2 mb-2"></div>
                <Text
                    variant="body1"
                    css={{
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: `${window.innerWidth > 992 ? '28px' : '18px'}`,
                        color: '#FFFFFF',
                        textAlign: 'center',
                        marginBottom: '10px !important',
                    }}
                >
                    Oops! It seems you're accessing the interview from a mobile device
                </Text>
                <Text
                    variant="body1"
                    css={{
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: `${window.innerWidth > 992 ? '28px' : '16px'}`,
                        color: '#FFFFFF',
                        textAlign: 'center',
                    }}
                >
                    Certain features may not work on mobile. To proceed smoothly, please switch to a desktop or laptop
                    to continue your interview.
                </Text>
            </Flex>
        </Flex>
    );
}

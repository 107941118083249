import React, { memo, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSubmitLoading } from '../../../../redux/reducer/interviewReducer';
import 'split-pane-react/esm/themes/default.css';

const Loader = memo(() => {
    return (
        <div className="wrapper-4">
            <div className="loader"></div>
        </div>
    );
});

const McqQuestionBar = ({ isPlaying, displayInput, question, questionNumberTotal, stopForProceed }) => {
    const submitLoading = useSelector(getSubmitLoading);
    const handleCopy = (e) => {
        e.preventDefault();
    };
    const handleCut = (e) => {
        e.preventDefault();
    };

    const instructionContent = `
    1. Understand the question thoroughly before selecting an answer.
    2. There is no penalty for incorrect answers, so attempt all questions.
    3. Once you proceed to the next question, you cannot navigate to previous questions.
    4. This test is monitored using live video and audio. Ensure your camera and microphone are active at all times.
    5. Ensure that you have a stable internet connection6. Keep track of the timer displayed on your screen and ensure all questions are attempted within the allotted time.
    7. When the time runs out, the test will be auto-submitted. Make sure to complete all questions before the timer ends.
    8. Please click on the Proceed button to start the aptitude section.`;

    return (
        <div className="code-editor-container m-0">
            {submitLoading && <Loader />}
            <div className="code-editor-header"></div>
            <div className="editor-container dark-bg mcq-question-container">
                {isPlaying || stopForProceed ? (
                    <>
                        <div className="mb-3" style={{ color: '#EDB837' }}>
                            Instructions for Aptitude section:
                        </div>
                        <pre style={{ whiteSpace: 'pre-wrap', margin: '0px' }} onCopy={handleCopy} onCut={handleCut}>
                            <code
                                dangerouslySetInnerHTML={{
                                    __html: `${instructionContent}`,
                                }}
                            />
                        </pre>
                    </>
                ) : (
                    <>
                        <div className="mb-3" style={{ color: '#EDB837' }}>
                            Question {questionNumberTotal}:
                        </div>
                        <pre style={{ whiteSpace: 'pre-wrap', margin: '0px' }} onCopy={handleCopy} onCut={handleCut}>
                            <code
                                dangerouslySetInnerHTML={{
                                    __html: `${question}`,
                                }}
                            />
                        </pre>
                    </>
                )}
            </div>
        </div>
    );
};

export default McqQuestionBar;

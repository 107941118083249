import { useDispatch } from 'react-redux';
import React, { useEffect, useRef } from 'react';
import { useScreenShare } from './ScreenshareContext';

const Viewer = ({ updateSocket, screenShareVidRef, setScreenShareVidRef, roomId }) => {
    const { stream } = useScreenShare();
    const videoRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (stream) {
            const videoTrack = stream.getVideoTracks()[0];
            const imageCapture = new ImageCapture(videoTrack);

            const sendFrame = async () => {
                if (videoTrack.readyState === 'live') {
                    try {
                        const bitmap = await imageCapture.grabFrame();
                        const canvas = document.createElement('canvas');
                        canvas.width = bitmap.width;
                        canvas.height = bitmap.height;
                        const context = canvas.getContext('2d');
                        context.drawImage(bitmap, 0, 0, canvas.width, canvas.height);

                        // Convert canvas to base64 and send to server
                        const base64Data = canvas.toDataURL('image/jpeg');
                        setScreenShareVidRef(base64Data);
                        updateSocket('screenShareVidRef', base64Data);

                        requestAnimationFrame(sendFrame);
                    } catch (err) {
                        console.error('Error capturing frame:', err);
                    }
                }
            };
            sendFrame();
        }
    }, [stream]);

    useEffect(() => {
        if (stream && videoRef.current) {
            videoRef.current.srcObject = stream;
            videoRef.current.play();
        }
    }, [stream]);

    return (
        <div>
            {/* <h2>View Shared Screen</h2>
            {stream ? (
                <video
                    ref={videoRef}
                    autoPlay
                    muted
                    style={{ width: "100%", border: "1px solid black", marginTop: "10px" }}
                />
            ) : (
                <p>No screen is being shared. Start sharing your screen first.</p>
            )} */}
        </div>
    );
};

export default Viewer;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useEffect, useRef, useState } from 'react';
import useSound from 'use-sound';
import { Box, Flex } from '@100mslive/roomkit-react';
import FullPageProgress from '../../components/FullPageProgress';
import { GridCenterView } from '../../components/gridView';
import { formatTime } from '../Feedback/components/Helpers';
import MobileNextPopUpBeam from './components/mobile/MobileNextPopUpBeam';
import CodeEditor from './components/portal/CodeEditor';
import beep from '../../assets/audio/beep.mp3';
import VideoList from '../VideoList';
import { QUESTION_TYPES } from '../../common/constants';

export const Beam = ({
    isPlaying,
    displayInput,
    prepareTime,
    answerTime,
    popupInputs,
    candidatePeer,
    codeEditorForm,
    hasAnythingChangedInCodeEditor,
    setIsSubmitModalOpen,
    isSubmitModalOpen,
    screenShareVidRef,
}) => {
    let currentAudio = null;
    const [playBeep] = useSound(beep);
    const [canPlayAudio, setCanPlayAudio] = useState(false);
    // const videoRef = useRef(null);

    // const img = new Image();
    // img.src = screenShareVidRef;

    // img.onload = () => {
    //     const canvas = videoRef.current;
    //     const context = canvas.getContext('2d');
    //     context.drawImage(img, 0, 0, canvas.width, canvas.height);
    // };

    useEffect(() => {
        // Simulate the click event immediately to trigger the audio playback enable
        const enableAudioPlayback = () => {
            if (!canPlayAudio) {
                setCanPlayAudio(true);
                console.log('Audio playback enabled after simulated user interaction');
            }
        };

        // Simulate the click immediately after the component mounts
        enableAudioPlayback();
        return () => {
            // Cleanup if necessary
        };
    }, []);

    const playAudio = async (url) => {
        try {
            if (currentAudio) {
                currentAudio.pause();
                currentAudio.currentTime = 0;
                currentAudio = null;
                console.log('Stopped any currently playing audio');
            }
            const audio = new Audio(url);
            currentAudio = audio;
            console.log(`Playing audio from URL: ${url}`);
            await audio.play();
            console.log('Audio playback started successfully');
        } catch (error) {
            console.error('Error during audio playback:', error);
        }
    };

    useEffect(() => {
        console.log(
            `canPlayAudio: ${canPlayAudio}, isPlaying: ${isPlaying}, displayInput?.audioData: ${displayInput?.audioData}, displayInput?.audio: ${displayInput?.audio}`
        );
        if (canPlayAudio && (isPlaying || displayInput?.audio)) {
            console.log('isPlaying is true. Checking displayInput for audio playback...');
            if (displayInput?.audioData) {
                console.log('Playing synthesized speech for message:', displayInput?.message);
                const blob = new Blob([displayInput?.audioData], { type: 'audio/wav' });
                const url = URL.createObjectURL(blob);
                playAudio(url);
            }
            if (displayInput?.audio) {
                console.log('Playing audio file from displayInput.audio:', displayInput.audio);
                playAudio(displayInput.audio);
            }
        }
    }, [canPlayAudio, displayInput?.audio, displayInput?.audioData]);

    useEffect(() => {
        if (displayInput?.playBeepSound) {
            console.log('Playing beep sound...');
            playBeep();
        }
    }, [displayInput?.playBeepSound]);

    const showText = (textToShow) => textToShow.split('\n').map((line, index) => <div key={index}>{line}</div>);

    if (popupInputs?.questionType === QUESTION_TYPES.CODING)
        return (
            <Suspense fallback={<FullPageProgress />}>
                <Flex
                    css={{
                        width: '100%',
                        position: 'relative',
                        overflow: 'hidden',
                        height: 'auto',
                        flexGrow: '1',
                    }}
                    direction={{ '@initial': 'row', '@md': 'column' }}
                    className="video_question_container"
                >
                    <Flex
                        css={{
                            size: '100%',
                            flexDirection: 'column',
                            position: 'relative',
                        }}
                        className="video_container"
                        direction={{
                            '@initial': 'column',
                            '@md': 'column',
                        }}
                    >
                        <CodeEditor
                            form={codeEditorForm}
                            hasAnythingChangedInCodeEditor={hasAnythingChangedInCodeEditor}
                            setIsSubmitModalOpen={setIsSubmitModalOpen}
                            isSubmitModalOpen={isSubmitModalOpen}
                            lastQuestion={false}
                            isPlaying={isPlaying}
                            question=""
                        />
                    </Flex>
                    <div className="ask-coding-question-div-beam">
                        <div className="ask-question d-flex justify-content-end">
                            Time To Answer Remaining : {formatTime(answerTime) ?? '00:00'}
                        </div>
                        <div className="question-number">IncBot</div>
                        <div className="ask-question">
                            {isPlaying || (!isPlaying && !popupInputs?.isDisabled)
                                ? showText(displayInput?.message)
                                : showText(displayInput?.displayText)}
                        </div>
                    </div>
                    <div className="video-tile-container video-mini-tile">
                        <VideoList peers={candidatePeer} maxTileCount={1} isTile />
                    </div>
                </Flex>
            </Suspense>
        );

    return (
        <Suspense fallback={<FullPageProgress />}>
            <Box
                css={{
                    width: '100%',
                    height: 'calc(100% - 64px)',
                }}
            >
                <MobileNextPopUpBeam
                    interviewFinished={popupInputs?.questionNumber > popupInputs?.totalQuestions}
                    popupInputs={popupInputs}
                />
                <div className="d-flex h-100 w-100 align-items-center justify-content-center">
                    <Flex
                        css={{
                            height: 'calc(100% - 10vh + 22px)',
                            width: '70%',
                            margin: 'auto 20px',
                        }}
                    >
                        <GridCenterView peers={candidatePeer} maxTileCount={1} />
                        {/* <canvas ref={videoRef} width={1080} height={607} style={{ border: '1px solid black' }}></canvas> */}
                    </Flex>
                    <div className="ask-question-div-beam-code">
                        <div className="question-number">IncBot</div>
                        <div className="ask-question">
                            {isPlaying || (!isPlaying && !popupInputs?.isDisabled)
                                ? displayInput?.message
                                : displayInput?.displayText}
                        </div>
                        {displayInput?.codeBlock && <div className="code-block mobile">{displayInput?.codeBlock}</div>}
                        {prepareTime || answerTime ? (
                            <>
                                <div className="ask-question">Time To Prepare Remaining : {prepareTime}</div>
                                <div className="ask-question">Time To Answer Remaining : {answerTime}</div>
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </Box>
        </Suspense>
    );
};

import React from 'react';
import moment from 'moment';
import { capitalizeAll } from './components/Helpers';
import TotalScoreBar from './components/TotalScoreBar';
import MaleAvatar from '../../assets/images/MaleAvatar.svg';
import RecommendedGreen from '../../assets/images/RecommendedGreen.svg';
import RecommendedRed from '../../assets/images/RecommendedRed.svg';
import RecommendedYellow from '../../assets/images/RecommendedYellow.svg';
export const Profile = ({ data, version, duration, totalAptitudeTime }) => {
    return (
        <>
            <div className="profile-div">
                <div className="profile-img-div">
                    <img className="profile-img-logo" src={data?.image ? data?.image : MaleAvatar} alt="Logo" />
                </div>
                <div className="profile-details-div desktop-mode">
                    <span className="common-profile-text profile-intId common-profile-text-block">{data?.code}</span>
                    <span className="common-profile-text profile-details-text common-profile-text-block">
                        {capitalizeAll(data?.name)} | {capitalizeAll(data?.position)}
                    </span>
                    <span className="common-profile-text profile-details-text common-profile-text-block">
                        Company: {data?.company}
                    </span>
                    <span className="common-profile-text profile-contact common-profile-text-block">
                        {data?.email} | {data?.phone}
                    </span>
                    <span className="common-profile-text profile-contact common-profile-text-block">
                        Date of Interview: {moment(data?.interviewDate).format('llll')}
                    </span>
                    {version > 7 && (
                        <span className="common-profile-text profile-contact common-profile-text-block">
                            Interview Duration: {Math.floor(Number(duration?.totalTimeTaken + totalAptitudeTime) / 60)}{' '}
                            mins{' '}
                            {Number(duration?.totalTimeTaken + totalAptitudeTime) % 60 > 0
                                ? `${Number(duration?.totalTimeTaken + totalAptitudeTime) % 60}`
                                : ''}{' '}
                            {Number(duration?.totalTimeTaken + totalAptitudeTime) % 60 > 0 ? `secs` : ''} Out of{' '}
                            {Math.floor(Number(duration?.totalDuration) / 60)}
                            {`${Math.floor(Number(duration?.totalDuration) / 60) <= 1 ? ' min' : ' mins'}`}
                            {Number(duration?.totalDuration) % 60 > 0
                                ? ` ${Number(duration?.totalDuration) % 60} ${Number(duration?.totalDuration) % 60 <= 1 ? 'sec' : 'secs'}`
                                : ''}
                        </span>
                    )}
                </div>
                {version === 5 ? (
                    <div className="rating-profile">
                        <TotalScoreBar percentage={data?.finalRating} totalPer={10} header />
                        <div className="rating-text">
                            {data?.finalRating > 6
                                ? 'Recommended'
                                : data?.finalRating > 4
                                  ? 'Cautiously Recommended'
                                  : 'Not Recommended'}
                        </div>
                    </div>
                ) : (
                    <div className="profile-details-status">
                        <img
                            className="profile-recommended-img"
                            src={
                                data?.finalRating > 6
                                    ? RecommendedGreen
                                    : data?.finalRating > 4
                                      ? RecommendedYellow
                                      : RecommendedRed
                            }
                            alt="Logo"
                        />
                    </div>
                )}
            </div>
            <div className="profile-details-div mobile-mode">
                <div className="profile-details-div-1">
                    <span className="common-profile-text profile-intId d-block">{data?.code}</span>
                    <span className="common-profile-text profile-details-text d-block">
                        {capitalizeAll(data?.name)} | {capitalizeAll(data?.position)}
                    </span>
                    <span className="common-profile-text profile-details-text d-block">Company: {data?.company}</span>
                    <span className="common-profile-text profile-contact d-block">
                        {data?.email} | {data?.phone}
                    </span>
                    <span className="common-profile-text profile-contact d-block">
                        Date of Interview: {moment(data?.interviewDate).format('llll')}
                    </span>
                    {version > 7 && (
                        <span className="common-profile-text profile-contact d-block">
                            Interview Duration: {Math.floor(Number(duration?.totalTimeTaken + totalAptitudeTime) / 60)}{' '}
                            mins{' '}
                            {Number(duration?.totalTimeTaken + totalAptitudeTime) % 60 > 0
                                ? `${Number(duration?.totalTimeTaken + totalAptitudeTime) % 60}`
                                : ''}{' '}
                            {Number(duration?.totalTimeTaken + totalAptitudeTime) % 60 > 0 ? `secs` : ''} Out of{' '}
                            {Math.floor(Number(duration?.totalDuration) / 60)}
                            {`${Math.floor(Number(duration?.totalDuration) / 60) <= 1 ? ' min' : ' mins'}`}
                            {Number(duration?.totalDuration) % 60 > 0
                                ? ` ${Number(duration?.totalDuration) % 60} ${Number(duration?.totalDuration) % 60 <= 1 ? 'sec' : 'secs'}`
                                : ''}
                        </span>
                    )}
                </div>
            </div>
        </>
    );
};

import React from 'react';
import { getConfidenceRating, getSkill, getStatusV } from './components/Helpers';
export const SoftSkillAssessment = ({ data, version, showSoftSkills }) => {
    return (
        <div className="skill-rating-div">
            {version < 5 && (
                <div className="skills-div">
                    <span className="soft-skills-span">Soft Skill Assessment</span>
                </div>
            )}
            <div className="mandatory-skills-graph-wrapper">
                {data &&
                    data.length > 0 &&
                    data.map((item, key) =>
                        version < 7 || (version >= 7 && showSoftSkills[item?.title]) ? (
                            <div className="individual-graph-wrapper break-stop" key={key}>
                                {item?.title !== 'Confidence' ? (
                                    <>
                                        <img className="graph-img" src={getSkill(item?.value)} alt="Rating" />
                                        <p className="graph-title" style={{ margin: '0px' }} title={item?.title}>
                                            {item.title
                                                ? item.title.length > 21
                                                    ? item.title.substring(0, 21) + '...'
                                                    : item.title
                                                : ''}
                                        </p>
                                        <p
                                            className="graph-soft-skills"
                                            style={{ margin: '0px', 'line-height': '0px' }}
                                        >
                                            <span
                                                className={`graph-descp-comment ${getStatusV(item.value)?.softColor}`}
                                            >
                                                {getStatusV(item.value)?.title.substring(0, 21)}
                                            </span>
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        <img
                                            className="graph-img"
                                            src={getConfidenceRating(item?.value)}
                                            alt="Rating"
                                        />
                                        <p className="graph-title" style={{ margin: '0px' }} title={item?.title}>
                                            {item.title
                                                ? item.title.length > 21
                                                    ? item.title.substring(0, 21) + '...'
                                                    : item.title
                                                : ''}
                                        </p>
                                        <p
                                            className="graph-soft-skills"
                                            style={{ margin: '0px', 'line-height': '0px' }}
                                        >
                                            <span
                                                className={`graph-descp-comment ${
                                                    item?.value > 4 ? 'status-blue' : 'status-red'
                                                }`}
                                            >
                                                {item?.value > 8
                                                    ? 'High'
                                                    : item?.value > 6
                                                      ? 'Good'
                                                      : item?.value > 4
                                                        ? 'Moderate'
                                                        : 'Low'}
                                            </span>
                                        </p>
                                    </>
                                )}
                            </div>
                        ) : (
                            <></>
                        )
                    )}
                {version > 4 && <div className="bar-chart-title">Soft skills Assessment</div>}
            </div>
        </div>
    );
};

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Webcam from 'react-webcam';
import io from 'socket.io-client';
import { createAndUpdateScreenshots, fetchScreenShotImage, fullFaceDetection } from '../../../../redux/action';
import { generateUrl, isMobileOrTabletDevice, isValidDataURI } from '../../../../utils/helper';

const VideoStream = ({
    roomId,
    interviewId,
    setEyeMovement,
    setFaceCount,
    setDeviceCount,
    saveMovement,
    eyeMovement,
    faceCount,
    deviceCount,
    lookingTimer,
}) => {
    const style = () => ({
        position: 'absolute',
        zIndex: -2,
        top: -200,
        right: 0,
    });

    const dispatch = useDispatch();
    const webcamRef = useRef(null);
    const [socketAnswer, setSocketAnswer] = useState(null);
    let proctoringSocket, faceExpressionSocket;

    useEffect(() => {
        if (!socketAnswer || socketAnswer.roomID !== roomId) return;
        const handleEyeMovement = () => {
            const { EYE_MOVEMENT, HEAD_MOVEMENT, num_faces, mobile, Laptop, dataURI, currentDate } = socketAnswer;
            if (
                ['right', 'left', 'down', 'up'].includes(EYE_MOVEMENT) ||
                ['Looking Left', 'Looking Right', 'Looking Down', 'Looking Up'].includes(HEAD_MOVEMENT)
            ) {
                setEyeMovement((prevState) => ({
                    ...prevState,
                    uri: !prevState.uri || prevState.time === 5 ? dataURI : prevState.uri,
                    date: currentDate,
                    time: prevState.time + 1,
                }));
            } else if (EYE_MOVEMENT === 'center' && HEAD_MOVEMENT === 'Looking Forward') {
                if (eyeMovement.time > lookingTimer) {
                    saveMovement(eyeMovement.time, 'Looking away from screen', eyeMovement.uri, eyeMovement.date);
                }
                setEyeMovement({
                    time: 0,
                    uri: '',
                    date: '',
                });
            }
            if (num_faces) {
                if (num_faces > 1) {
                    setFaceCount((prevState) => ({
                        ...prevState,
                        uri: !prevState.uri || prevState.time === 2 ? dataURI : prevState.uri,
                        date: currentDate,
                        time: prevState.time + 1,
                    }));
                } else if (num_faces === 1) {
                    if (faceCount.time > 5) {
                        saveMovement(faceCount.time, 'Multiple faces detected', faceCount.uri, faceCount.date);
                    }
                    setFaceCount({
                        time: 0,
                        uri: '',
                        date: '',
                    });
                }
            }
            if (mobile || Laptop) {
                setDeviceCount((prevState) => ({
                    ...prevState,
                    uri: !prevState.uri || prevState.time === 2 ? dataURI : prevState.uri,
                    date: currentDate,
                    time: prevState.time + 1,
                }));
            } else if (mobile === 0 && Laptop === 0) {
                if (deviceCount.time > 5) {
                    saveMovement(deviceCount.time, 'Additional devices detected', deviceCount.uri, deviceCount.date);
                }
                setDeviceCount({
                    time: 0,
                    uri: '',
                    date: '',
                });
            }
        };
        handleEyeMovement();
    }, [socketAnswer]);

    useEffect(() => {
        const startStreaming = async () => {
            try {
                // Initialize sockets
                proctoringSocket = io(generateUrl('proctoring'), { transports: ['websocket'] });
                faceExpressionSocket = io(generateUrl('face_expression'), { transports: ['websocket'] });

                // Listen for socket events
                proctoringSocket.on('connect', () => {
                    console.log('Connected to Proctoring Server');
                    const offer = { roomID: roomId, currentDate: new Date() };
                    setTimeout(() => startSendingFrames(proctoringSocket, offer), 2000); // Delay to allow webcam initialization
                });

                faceExpressionSocket.on('connect', () => {
                    console.log('Connected to Face Expression Server');
                    const offer = {
                        roomID: roomId,
                        interviewId,
                        currentDate: new Date(),
                        mode: process.env.REACT_APP_ENV || 'dev',
                    };
                    setTimeout(() => startSendingFrames(faceExpressionSocket, offer), 2000); // Delay to allow webcam initialization
                });

                proctoringSocket.on('answer', async (data) => {
                    const response = JSON.parse(data);
                    setSocketAnswer(response);
                });
            } catch (error) {
                console.error('Error accessing camera:', error);
            }
        };

        const startSendingFrames = async (socket, offer) => {
            const sendOffer = async () => {
                if (!webcamRef.current) {
                    console.error('Webcam not ready');
                    return;
                }
                const blobPath = webcamRef.current.getScreenshot();
                if (blobPath) {
                    offer.dataURI = blobPath;
                    offer.frames = [blobPath];
                    socket.emit('offer', offer);
                    if (socket.connected) {
                        setTimeout(sendOffer, 1000); // Reschedule to run again
                    }
                } else {
                    console.error('Screenshot failed: Webcam not ready');
                }
            };

            sendOffer(); // Start the initial call
        };

        const startStreamingCleanup = () => {
            if (proctoringSocket && faceExpressionSocket) {
                proctoringSocket.close();
                faceExpressionSocket.close();
            }
        };

        startStreaming();
        return startStreamingCleanup;
    }, [roomId, interviewId]);

    const capture = useCallback(() => {
        const blobPath = webcamRef.current?.getScreenshot();
        if (isValidDataURI(blobPath)) {
            const fetchScreenShot = (faceDetected) => {
                dispatch(
                    fetchScreenShotImage({
                        image: blobPath,
                        interviewId: roomId,
                        type: 10,
                        callback: (image) => {
                            if (isValidDataURI(image)) {
                                dispatch(
                                    createAndUpdateScreenshots({
                                        roomId: roomId,
                                        screenshots: { url: image, type: 'default', faceDetected: faceDetected },
                                        mode: 'new',
                                    })
                                );
                            }
                        },
                    })
                );
            };
            dispatch(
                fullFaceDetection({
                    data: { base64_image: blobPath },
                    onSuccess: (hasFullFaceDetected) => {
                        fetchScreenShot(!!hasFullFaceDetected);
                    },
                    onFailure: () => {
                        fetchScreenShot(false);
                    },
                    doFinally: () => {},
                })
            );
        }
    }, [dispatch, roomId, webcamRef]);

    useEffect(() => {
        if (isMobileOrTabletDevice()) {
            let interval;
            const delayedCapture = () => {
                capture();
                interval = setInterval(() => {
                    capture();
                }, 300000);
            };
            const initialDelay = 30000;
            const timerId = setTimeout(delayedCapture, initialDelay);
            return () => {
                clearTimeout(timerId);
                clearInterval(interval);
            };
        } else {
            let interval;
            const delayedCapture = () => {
                capture();
                interval = setInterval(
                    () => {
                        capture();
                    },
                    10 * 60 * 1000
                );
            };
            const initialDelay = 30000;
            const timerId = setTimeout(delayedCapture, initialDelay);
            return () => {
                clearTimeout(timerId);
                clearInterval(interval);
            };
        }
    }, []);

    return (
        <div style={style()}>
            <Webcam
                audio={false}
                height={240}
                screenshotFormat="image/jpeg"
                screenshotQuality={98}
                width={320}
                ref={webcamRef}
                style={{ visibility: 'hidden' }}
                videoConstraints={{
                    width: 320,
                    height: 240,
                    facingMode: 'user',
                }}
            />
        </div>
    );
};

export default VideoStream;

import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import eraser from '../../../../assets/images/erase.svg';
import VideoList from '../../../VideoList';
import { QUESTION_TYPES } from '../../../../common/constants';

export default function SidebarQuestion({
    question,
    questionType = 'global',
    candidatePeer,
    position,
    setPosition,
    codeBlock,
    isQuestionTypeMcq = false,
    mcqForm,
    selectedOption,
    setSelectedOption,
    isSpeaking = false,
    stopForProceed,
}) {
    console.log('question display: ', question);
    const [mcqInstructions, setMcqInstruction] = useState('');
    const handleCopy = (e) => {
        e.preventDefault();
    };
    const handleCut = (e) => {
        e.preventDefault();
    };
    const handleDrag = (e, ui) => {
        const { x, y } = ui;
        setPosition({ x, y });
    };

    const options = [
        { id: 1, label: mcqForm?.option_1 },
        { id: 2, label: mcqForm?.option_2 },
        { id: 3, label: mcqForm?.option_3 },
        { id: 4, label: mcqForm?.option_4 },
    ];

    const handleOptionChange = (id) => {
        setSelectedOption(id);
    };

    const clearResponse = () => {
        setSelectedOption(null);
    };

    useEffect(() => {
        if (question && isSpeaking && isQuestionTypeMcq) setMcqInstruction(question);
    }, [isSpeaking, isQuestionTypeMcq]);

    return (
        <div className="sidebar-question-container">
            <div className="question-number">IncBot</div>
            {isQuestionTypeMcq ? (
                isSpeaking || stopForProceed ? (
                    <div>
                        <div className="ask-question">
                            <pre
                                style={{ whiteSpace: 'pre-wrap', margin: '0px' }}
                                onCopy={handleCopy}
                                onCut={handleCut}
                            >
                                <code
                                    dangerouslySetInnerHTML={{
                                        __html: `${mcqInstructions}`,
                                    }}
                                />
                            </pre>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="options-container">
                            <div className="options-header">
                                <span>Select Option</span>
                                <button className="clear-response" onClick={clearResponse}>
                                    <img src={eraser} className="clear-icon" alt="Clear response" /> Clear response
                                </button>
                            </div>
                            <div className="options-list">
                                {options.map((option) => (
                                    <label key={option.id} className="option-item">
                                        <input
                                            type="radio"
                                            name="options"
                                            checked={selectedOption === option.id}
                                            onChange={() => handleOptionChange(option.id)}
                                        />
                                        <span
                                            className={`radio-button ${selectedOption === option.id ? 'selected' : ''}`}
                                        ></span>
                                        <span className="option-label">{option.label}</span>
                                    </label>
                                ))}
                            </div>
                        </div>
                    </div>
                )
            ) : (
                <div className="ask-question">
                    <pre style={{ whiteSpace: 'pre-wrap', margin: '0px' }} onCopy={handleCopy} onCut={handleCut}>
                        <code
                            dangerouslySetInnerHTML={{
                                __html: `${question}`,
                            }}
                        />
                    </pre>
                    {codeBlock && question && <div className="code-block mt-2">{codeBlock}</div>}
                </div>
            )}
            {[
                QUESTION_TYPES.CODING,
                QUESTION_TYPES.MCQ.LOGICAL,
                QUESTION_TYPES.MCQ.NUMBER,
                QUESTION_TYPES.MCQ.QUANTITATIVE,
                QUESTION_TYPES.MCQ.VERBAL,
            ].includes(questionType) &&
                candidatePeer?.length > 0 && (
                    <Draggable position={position} onDrag={handleDrag}>
                        <div className="video-tile-container draggable video-mini-tile">
                            <VideoList peers={candidatePeer} maxTileCount={1} isTile />
                        </div>
                    </Draggable>
                )}
        </div>
    );
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
// eslint-disable-next-line import/no-named-as-default
import useSound from 'use-sound';
import { Box, Flex } from '@100mslive/roomkit-react';
import { GridCenterView } from '../components/gridView';
import CodeEditor from '../components/Pages/components/portal/CodeEditor';
import McqQuestionBar from '../components/Pages/components/portal/McqQuestionBar';
import Question from '../components/Pages/components/portal/Question';
import QuestionInfo from '../components/Pages/components/portal/QuestionInfo';
import beep from '../assets/audio/beep.mp3';
import { useDispatch, useSelector } from 'react-redux';
import { switchTab } from '../redux/action';
export const ConferenceMainView = ({
    form,
    toggleControls,
    questionInfo,
    isPlaying,
    prepareTime,
    setPrepareTime,
    answerTime,
    setAnswerTime,
    handleEndCall,
    recording,
    interviewStarted,
    onlineStatus,
    setAnswerDuration,
    answerDuration,
    handleSubmit,
    candidatePeer,
    displayInput,
    setDisplayInput,
    isUpdated,
    playAudio,
    skipTimer,
    waitingPrepTime,
    waitingAnswerTime,
    setWaitingAnswerTime,
    startRecording,
    timerEnd,
    updateSocket,
    setCodeEditorForm,
    codeEditorForm,
    hasAnythingChangedInCodeEditor,
    setHasAnythingChangedInCodeEditor,
    isQuestionTypeCoding,
    setIsSubmitModalOpen,
    isSubmitModalOpen,
    position,
    setPosition,
    isQuestionTypeMcq,
    mcqForm,
    selectedOption,
    setSelectedOption,
    saveMcqQuestion,
    aptitude,
    stopForProceed,
    setStopForProceed,
    conversationData,
    handleConSubmit,
    speakText,
    evaluateNextQuestionRef,
}) => {
    const screenShareVid = useSelector((state) => state.Interview.screenShareVid);
    const [playBeep] = useSound(beep);
    const videoRef = useRef(null);
    const [questionEnded, setQuestionEnded] = useState(false);
    const [isNextClick, setIsNextClick] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isMcqAudioPlayed, setIsMcqAudioPlayed] = useState(false);
    const logSuccess = (message) => console.log(`✅ Success: ${message}`);
    const logError = (message, error) => console.error(`❌ Error: ${message}`, error);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!screenShareVid) {
            dispatch(
                switchTab({
                    interviewId: form?.interviewId,
                    message: 'Screen Share stopped',
                    callback: () => handleEndCall(false, 'Screen share stopped'),
                })
            );
        }
    }, [screenShareVid]);

    function getNextSectionStartIndex(index, questionsArraySize, aptitudeArray) {
        logSuccess('Calculating the next section start index');
        let randomQuestionsCount = questionsArraySize;
        let startIndex = 0;
        aptitudeArray.forEach((section) => {
            randomQuestionsCount -= section.questionCount;
        });
        if (index <= randomQuestionsCount) {
            logSuccess('Index within random questions, returning next index');
            return index + 1;
        }
        let relativeIndex = index - randomQuestionsCount;
        for (let i = 0; i < aptitudeArray.length; i++) {
            const questionCount = aptitudeArray[i].questionCount;
            const endIndex = startIndex + questionCount;
            if (relativeIndex >= startIndex + 1 && relativeIndex <= endIndex) {
                if (i === aptitudeArray.length - 1) {
                    logSuccess('Reached the last section, returning end index');
                    return questionsArraySize + 1;
                } else {
                    logSuccess('Returning the next section start index');
                    return randomQuestionsCount + endIndex + 1;
                }
            }
            startIndex = endIndex;
        }
        logError('Index calculation failed. Returning -1');
        return -1;
    }

    useEffect(() => {
        if (displayInput?.audio && !isQuestionTypeMcq) {
            logSuccess('Playing audio for non-MCQ question');
            playAudio(displayInput?.audio);
        } else if (displayInput?.audio && isQuestionTypeMcq && !isMcqAudioPlayed) {
            logSuccess('Playing audio for MCQ question');
            playAudio(displayInput?.audio);
            setIsMcqAudioPlayed(true);
        } else if (displayInput?.audio === '' && displayInput?.message && displayInput?.audioPlay) {
            logSuccess('Speaking text from display input');
            speakText(displayInput?.message);
        }
    }, [displayInput?.audio, displayInput?.message]);

    useEffect(() => {
        const totalQuestion = form?.totalQuestion ? parseInt(form?.totalQuestion) : 0;
        if (!waitingPrepTime && !isPlaying && questionInfo?.questionNumber > totalQuestion && answerTime === 0) {
            logSuccess('Auto-submitting interview as all questions are completed');
            handleEndCall(false, 'Auto Interview Submitted');
        }
    }, [isPlaying, waitingPrepTime]);

    useEffect(() => {
        if (isNextClick) {
            setIsNextClick(false);
            logSuccess('Resetting next click flag');
        }
    }, [isNextClick]);

    useEffect(() => {
        if (displayInput?.playBeepSound) {
            logSuccess('Playing beep sound');
            const value = {
                ...displayInput,
                playBeepSound: false,
                callHandleSubmit: false,
                clearPlaying: false,
                audioData: null,
            };
            setDisplayInput(value);
            updateSocket('displayInput', value);
        }
    }, [displayInput?.playBeepSound]);

    useEffect(() => {
        const totalQuestion = form?.totalQuestion ? parseInt(form?.totalQuestion) : 0;
        if (
            !waitingPrepTime &&
            !isPlaying &&
            interviewStarted &&
            questionInfo?.questionNumber <= totalQuestion &&
            answerTime > 0 &&
            !isQuestionTypeCoding &&
            !isQuestionTypeMcq
        ) {
            let prepareTimer;
            const prepTimerFunc = async () => {
                if (prepareTime > 0) {
                    setPrepareTime(prepareTime - 1);
                    updateSocket('prepareTime', prepareTime - 1);
                    logSuccess('Decrementing prepare time');
                } else {
                    if (!recording && !isQuestionTypeCoding) {
                        await startRecording();
                        logSuccess('Started recording');
                    }
                    if (!isNextClick) {
                        const dValue = {
                            ...displayInput,
                            playBeepSound: true,
                            callHandleSubmit: false,
                            clearPlaying: false,
                            audioData: null,
                        };
                        setDisplayInput(dValue);
                        updateSocket('displayInput', dValue);
                        playBeep();
                        logSuccess('Beep played for next question');
                    }
                    clearInterval(prepareTimer);
                }
            };
            prepareTimer = setInterval(() => {
                !onlineStatus ? clearInterval(prepareTimer) : prepTimerFunc();
            }, 1000);
            return () => {
                clearInterval(prepareTimer);
            };
        }
    }, [waitingPrepTime, isPlaying, prepareTime, onlineStatus]);

    useEffect(() => {
        const totalQuestion = form?.totalQuestion ? parseInt(form?.totalQuestion) : 0;
        if (
            !waitingPrepTime &&
            !isPlaying &&
            (isQuestionTypeCoding || recording || isQuestionTypeMcq) &&
            prepareTime === 0 &&
            interviewStarted &&
            questionInfo?.questionNumber <= totalQuestion &&
            !stopForProceed
        ) {
            let answerTimer;
            if (!waitingAnswerTime) {
                setWaitingAnswerTime(true);
                logSuccess('Answer timer started');
            }
            const ansTimerFunc = () => {
                if (answerTime > 0) {
                    setAnswerTime(answerTime - 1);
                    updateSocket('answerTime', answerTime - 1);
                    setAnswerDuration(answerDuration + 1);
                    logSuccess('Decrementing answer time');
                } else {
                    setWaitingAnswerTime(false);
                    setQuestionEnded(true);
                    setIsSubmitModalOpen(false);
                    clearInterval(answerTimer);
                    logSuccess('Answer timer ended');
                }
            };
            answerTimer = setInterval(async () => {
                !onlineStatus ? clearInterval(answerTimer) : ansTimerFunc();
            }, 1000);
            return () => {
                clearInterval(answerTimer);
            };
        }
    }, [
        waitingPrepTime,
        recording,
        prepareTime,
        answerTime,
        form,
        questionInfo,
        onlineStatus,
        isPlaying,
        stopForProceed,
    ]);

    useEffect(() => {
        const handleSubmission = async () => {
            if (
                !waitingPrepTime &&
                !isPlaying &&
                questionEnded &&
                !waitingAnswerTime &&
                answerTime === 0 &&
                !evaluateNextQuestionRef.current
            ) {
                if (conversationData?.conversationStatus && conversationData?.questionNumber < 2) {
                    logSuccess('Handling submission for conversational AI');
                    evaluateNextQuestionRef.current = true;
                    await handleConSubmit(conversationData?.conversationStatus, 'Desktop');
                } else if (isQuestionTypeMcq) {
                    logSuccess('Handling submission for MCQ');
                    const mcqNextQuestion = getNextSectionStartIndex(
                        questionInfo?.questionNumber,
                        form?.totalQuestion,
                        aptitude
                    );
                    evaluateNextQuestionRef.current = true;
                    await handleSubmit('Mobile', mcqNextQuestion);
                } else {
                    logSuccess('Handling regular question submission');
                    evaluateNextQuestionRef.current = true;
                    await handleSubmit('Mobile');
                }
            }
        };
        handleSubmission();
    }, [questionEnded, isPlaying, answerTime, waitingAnswerTime]);

    useEffect(() => {
        const totalQuestion = form?.totalQuestion ? parseInt(form?.totalQuestion) : 0;
        if (totalQuestion < questionInfo?.questionNumber) {
            timerEnd();
            logSuccess('Timer ended as all questions are completed');
        }
    }, [questionInfo?.questionNumber, form?.totalQuestion]);

    const handleNextQuestionClick = async () => {
        if (!isButtonDisabled) {
            evaluateNextQuestionRef.current = true;
            setIsNextClick(true);
            timerEnd();
            setIsButtonDisabled(true);
            try {
                if (conversationData?.conversationStatus && conversationData?.questionNumber < 2) {
                    logSuccess('Handling conversational AI next question');
                    await handleConSubmit(conversationData?.conversationStatus, 'Desktop');
                } else {
                    logSuccess('Handling next question submission');
                    await handleSubmit('Mobile');
                }
            } catch (error) {
                console.error('Error in handleNextQuestionClick:', error);
            } finally {
                setIsButtonDisabled(false);
                setIsSubmitModalOpen(false);
            }
        }
    };

    const handleNextMcqClick = async () => {
        if (!isButtonDisabled) {
            evaluateNextQuestionRef.current = true;
            setIsButtonDisabled(true);
            logSuccess('Handling MCQ next question');
            await saveMcqQuestion();
            await handleSubmit('Mobile');
            setIsButtonDisabled(false);
        }
    };

    return (
        <Box
            css={{
                w: '100%',
                flex: '1 1 0',
                minHeight: 0,
                paddingBottom: 'env(safe-area-inset-bottom)',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
            }}
            id="conferencing"
            data-testid="conferencing"
            onClick={toggleControls}
        >
            <QuestionInfo form={form} questionInfo={questionInfo} handleEndCall={handleEndCall} />
            <Flex
                css={{
                    width: '100%',
                    overflow: 'hidden',
                    height: 'auto',
                    flexGrow: '1',
                }}
                direction={{ '@initial': 'row', '@md': 'column' }}
                ref={videoRef}
                className="video_question_container"
            >
                <Flex
                    css={{
                        size: '100%',
                        flexDirection: 'column',
                        position: 'relative',
                    }}
                    className="video_container"
                    direction={{
                        '@initial': 'column',
                        '@md': 'column',
                    }}
                >
                    {isQuestionTypeCoding ? (
                        <CodeEditor
                            updateSocket={updateSocket}
                            form={codeEditorForm}
                            setForm={setCodeEditorForm}
                            handleSubmit={handleNextQuestionClick}
                            hasAnythingChangedInCodeEditor={hasAnythingChangedInCodeEditor}
                            setHasAnythingChangedInCodeEditor={setHasAnythingChangedInCodeEditor}
                            setIsSubmitModalOpen={setIsSubmitModalOpen}
                            isSubmitModalOpen={isSubmitModalOpen}
                            lastQuestion={questionInfo?.questionNumber === parseInt(form?.totalQuestion || 0)}
                            isPlaying={isPlaying}
                            question={questionInfo?.actualQuestion}
                        />
                    ) : isQuestionTypeMcq ? (
                        <McqQuestionBar
                            isPlaying={isPlaying}
                            displayInput={displayInput}
                            questionNumberTotal={questionInfo?.questionNumber}
                            question={questionInfo?.actualQuestion}
                            stopForProceed={stopForProceed}
                        />
                    ) : (
                        <GridCenterView peers={candidatePeer} maxTileCount={1} />
                    )}
                </Flex>
                {isUpdated && (
                    <Question
                        questionInfo={questionInfo}
                        isSpeaking={isPlaying}
                        totalQuestion={form?.totalQuestion ? parseInt(form?.totalQuestion) : 0}
                        isSkipEnabled={prepareTime < questionInfo?.prepareTime && prepareTime > 0}
                        prepareTime={prepareTime}
                        answerTime={answerTime}
                        skipTimer={skipTimer}
                        handleEndCall={handleEndCall}
                        candidatePeer={candidatePeer}
                        isButtonDisabled={isButtonDisabled}
                        handleNextQuestionClick={handleNextQuestionClick}
                        isNextButtonEnabled={
                            isQuestionTypeMcq ||
                            (prepareTime < questionInfo?.prepareTime && prepareTime > 0) ||
                            (answerTime < questionInfo?.timeToAnswer && answerTime > 0)
                        }
                        question={
                            isPlaying
                                ? displayInput?.message
                                : (questionInfo?.prepareTime !== prepareTime ||
                                        questionInfo?.timeToAnswer !== answerTime) &&
                                    answerTime > 0
                                  ? displayInput?.displayText
                                  : ''
                        }
                        codeBlock={displayInput?.codeBlock}
                        position={position}
                        setPosition={setPosition}
                        mcqForm={mcqForm}
                        isQuestionTypeMcq={isQuestionTypeMcq}
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        handleNextMcqClick={handleNextMcqClick}
                        stopForProceed={stopForProceed}
                        setStopForProceed={setStopForProceed}
                    />
                )}
            </Flex>
            <div style={{ height: 30, fontWeight: 800, color: 'white', textAlign: 'center' }} className="w-100 f-12">
                *Please note if you stop screen sharing, the interview will be ended
            </div>
        </Box>
    );
};

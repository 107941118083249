import React, { createContext, useContext, useState } from 'react';

// Context to share MediaStream
const ScreenShareContext = createContext();

export const ScreenShareProvider = ({ children }) => {
    const [stream, setStream] = useState(null);

    return <ScreenShareContext.Provider value={{ stream, setStream }}>{children}</ScreenShareContext.Provider>;
};

// Hook to use ScreenShareContext
export const useScreenShare = () => useContext(ScreenShareContext);

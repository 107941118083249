import React from 'react';
import moment from 'moment';
import { formatTime } from './components/Helpers';
export const Proctoring = ({ message, screenshots }) => {
    let finalMessage = message;
    const endIndex = finalMessage.findIndex((msg) => msg.text === 'Interview Ended');
    if (endIndex !== -1) {
        finalMessage = finalMessage.slice(0, endIndex + 1);
    }
    return (
        <div className="code-snippet-wrapper break-stop">
            <div className="skills-div">
                <span className="soft-skills-span">Proctoring Logs</span>
            </div>
            <div className="proctoring-message">
                {finalMessage &&
                    finalMessage.length > 0 &&
                    finalMessage.map((msg) => (
                        <div className="start-proctoring">
                            <div className={`proctoring-message-circle ${msg?.type}`}></div>
                            <div className="proctoring-message-text">
                                {moment(msg?.dateAndTime).format('llll')}{' '}
                                <span className={`message-text ${msg?.type} m-left`}>
                                    {msg?.text}
                                    {msg?.duration && msg?.duration > 0 && ` for ${formatTime(msg?.duration)} mins`}
                                </span>
                            </div>
                        </div>
                    ))}
            </div>
            {screenshots && screenshots.length > 0 && (
                <div className="proctoring-screenshots">
                    {screenshots.map((itm) => (
                        <div className="screenshots-proctoring">
                            <div className="screenshots-description">
                                <div className="proctoring-message-text">
                                    {moment(itm?.dateAndTime).format('llll')}
                                    <br />
                                    <span className={`message-text ${itm?.type}`}>
                                        {itm?.text}
                                        {itm?.duration && itm?.duration > 0 && ` for ${formatTime(itm?.duration)} mins`}
                                    </span>
                                </div>
                                <img src={itm?.url} alt="screenshots" />
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

import { FULL_FACE_DETECTION } from '../redux/action/types';
import { PRIVACY_POLICY, TERMS_AND_CONDITION } from './constants';

export const Toast = ({ msg }) => <>{msg}</>;

export const capitalizeAll = (s) => {
    if (typeof s !== 'string') return '';
    return s
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

export const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
};

export const generateQuestionSuffix = (question, time) => {
    const listA = [
        `${question}\nYou have ${time} seconds to begin your answer. You can record your response after the beep sound.`,
        `${question}\nYou have ${time} seconds to begin your answer. You can start after the beep sound.`,
        `${question}\nPlease answer after the beep sound.`,
        `${question}\nYou will have ${time} seconds to prepare before. Please answer after the beep sound.`,
    ];
    const randomIndexA = Math.floor(Math.random() * listA.length);
    return listA[randomIndexA];
};

export const removeQuotes = (input) => {
    if (typeof input === 'string') {
        // Remove leading and trailing quotes if they exist
        return input.replace(/^"(.*)"$/, '$1');
    }
    return input; // Return as-is if it's not a string
};

export const getLatencyText = (name) => {
    const lists = [`Okay ${name}.`, `Alright ${name}.`];
    // Select a random index from the lists array
    const randomIndex = Math.floor(Math.random() * lists.length);
    return lists[randomIndex];
};

export const noAnswer = (name) => {
    const value = `Okay, no worries ${name}.`;
    return value;
};

export const validateEmail = (email) => {
    const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9-]+\.[A-Za-z]{2,}$/;
    return regex.test(email);
};

// Check if the device is mobile or tablet
export const isMobileOrTabletDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return (
        /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini|tablet/.test(userAgent) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    ); // iPadOS detection
};

// Check if the device is strictly mobile (not tablet or iPad)
export const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return (
        /android|webos|iphone|ipod|blackberry|iemobile|opera mini/.test(userAgent) &&
        !(navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    ); // Exclude iPadOS
};

export const getBrowserInfo = () => {
    const userAgent = navigator.userAgent;
    const browserName = (() => {
        if (userAgent.indexOf('Chrome') !== -1) return 'Chrome';
        if (userAgent.indexOf('Firefox') !== -1) return 'Firefox';
        if (userAgent.indexOf('Safari') !== -1) return 'Safari';
        if (userAgent.indexOf('Edge') !== -1) return 'Edge';
        if (userAgent.indexOf('Opera') !== -1 || userAgent.indexOf('OPR') !== -1) return 'Opera';
        if (userAgent.indexOf('MSIE') !== -1 || userAgent.indexOf('Trident/') !== -1) return 'Internet Explorer';
        return 'Unknown';
    })();

    return {
        browserName,
        browserVersion: navigator.appVersion,
    };
};

export const getBrowserInfoMessage = () => {
    let message;
    let browserInfo = getBrowserInfo();
    message = `Browser: ${browserInfo?.browserName}, Browser Version: ${browserInfo?.browserName}, Device Type: ${
        isMobileDevice() ? 'Mobile' : isMobileOrTabletDevice() ? 'Tablet' : 'Desktop/Laptop'
    }`;
    return message;
};
export const prepareTimeOverLink =
    'https://storage.googleapis.com/incruiter-media/audio_file/audio-1701862643-82158.mp3';
export const answerTimeOverLink =
    'https://storage.googleapis.com/incruiter-media/audio_file/audio-1701862720-92073.mp3';
export const interviewTimeOverLink =
    'https://storage.googleapis.com/incruiter-media/audio_file/audio-1701862799-26131.mp3';

export const generateUrl = (urlMode) => {
    const envMode = process.env.REACT_APP_ENV;
    let frontendUrlPreFix, backendUrlPreFix, externalUrl, frontendPUrlPrefix, interviewUrl, socketUrlPrefix;
    let finalUrl;
    if (envMode === 'prod') {
        interviewUrl = 'meeting.';
        frontendPUrlPrefix = '';
        frontendUrlPreFix = '';
        socketUrlPrefix = '';
        backendUrlPreFix = 'api.';
        externalUrl = 'https://prod-in2.100ms.live/hmsapi/incruiter-prod.app.100ms.live/';
    } else if (envMode === 'test' || envMode === 'preprod') {
        interviewUrl = 'test.v1.interview.';
        frontendPUrlPrefix = 'test.';
        frontendUrlPreFix = 'test.v1.';
        socketUrlPrefix = 'test-';
        backendUrlPreFix = 'test.api.';
        externalUrl = 'https://prod-in2.100ms.live/hmsapi/incruiter-preprod-bot-room.app.100ms.live/';
    } else {
        interviewUrl = 'dev.v1.interview.';
        frontendPUrlPrefix = 'dev.';
        frontendUrlPreFix = 'dev.v1.';
        socketUrlPrefix = 'dev-';
        backendUrlPreFix = 'dev.api.';
        externalUrl = 'https://prod-in2.100ms.live/hmsapi/incruiter-dev-meet.app.100ms.live/';
    }
    if (urlMode === 'token_generation') {
        finalUrl = externalUrl;
    } else if (urlMode === 'socket') {
        finalUrl = `https://${socketUrlPrefix}video-feedback-system.incruiter.com`;
    } else if (urlMode === 'face_expression') {
        finalUrl = `https://${frontendPUrlPrefix}fer.incruiter.com`;
    } else if (urlMode === 'proctoring') {
        finalUrl = `https://${frontendPUrlPrefix}rtp.incruiter.com`;
    } else if (urlMode === 'interview_backend') {
        finalUrl = `https://${backendUrlPreFix}incruiter.com/interview-system/api/v1/incbot`;
    } else if (urlMode === 'video_backend') {
        finalUrl = `https://${backendUrlPreFix}incruiter.com/video-feedback-system/api/v1/video-platform`;
    } else if (urlMode === 'meeting') {
        finalUrl = `https://${interviewUrl}incruiter.com`;
    } else if (urlMode === FULL_FACE_DETECTION) {
        finalUrl = `https://${backendUrlPreFix}incruiter.com/extraction-ocr-services`;
    } else if (urlMode === 'context-handler') {
        finalUrl = `https://${backendUrlPreFix}incruiter.com/context-handler`;
    } else if (urlMode === PRIVACY_POLICY) {
        finalUrl = `https://${frontendUrlPreFix}incruiter.com/privacy-policy`;
    } else if (urlMode === TERMS_AND_CONDITION) {
        finalUrl = `https://${frontendUrlPreFix}incruiter.com/terms-and-condition`;
    } else if (urlMode === 'conversational-ai') {
        finalUrl = `wss://${frontendPUrlPrefix}conversational-ai.incruiter.com/ws`;
    }
    return finalUrl;
};

export const isValidDataURI = (dataURI) => {
    return dataURI && dataURI.length > 10 && !dataURI.includes('png');
};

export const parseMarkdown = (markdownContent) => {
    // Replace Markdown syntax with HTML equivalents
    const htmlContent = markdownContent
        // Headings
        .replace(/^# (.+)$/gm, '<h1>$1</h1>')
        .replace(/^## (.+)$/gm, '<h2>$1</h2>')
        .replace(/^### (.+)$/gm, '<h3>$1</h3>')
        .replace(/^#### (.+)$/gm, '<h4>$1</h4>')
        .replace(/^##### (.+)$/gm, '<h5>$1</h5>')
        .replace(/^###### (.+)$/gm, '<h6>$1</h6>')

        // Bold and Italic
        .replace(/\*\*(.+?)\*\*/g, '<strong>$1</strong>')
        .replace(/\*(.+?)\*/g, '<em>$1</em>')

        // Lists
        .replace(/^\s*-\s(.+)/gm, '<li>$1</li>')
        .replace(/^\s*1\.\s(.+)/gm, '<li>$1</li>')
        .replace(/^\s*\d+\.\s(.+)/gm, '<li>$1</li>')
        .replace(/<\/li><li>/g, '</li><li><ul>') // Wrap list items in <ul> tags
        .replace(/<\/li><\/ul><li>/g, '</li><li>') // Remove extra <ul> tags

        // Links
        .replace(/\[(.+?)\]\((\S+?)\)/g, '<a href="$2">$1</a>')

        // Images
        .replace(/!\[(.*?)\]\((.*?)\)/g, '<img alt="$1" src="$2" />')

        // Blockquotes
        .replace(/^\>(.*)$/gm, '<blockquote>$1</blockquote>')

        // Code blocks
        .replace(/```(.+?)\n([\s\S]+?)\n```/g, '<pre><code class="$1">$2</code></pre>')
        .replace(/``(.+?)``/g, '<code>$1</code>');

    return htmlContent;
};

export const getAssetSrc = (assetName) =>
    `https://incruiter.blob.core.windows.net/frontend/frontend-interview/assets/${assetName}`;

export const practiceQuestion = (number) => {
    let questionSet = null;

    switch (parseInt(number)) {
        case 1:
            questionSet = {
                response: `Hello, I hope you’re doing well.
I'm glad to have you here today.
I appreciate you taking the time to interview with us.
I'm looking forward to learning more about you and your experiences.
So, let's get started.
Tell me about yourself?.
You have 10 seconds to begin your answer. You can record your response after the beep sound.`,
                audioUrl: 'https://storage.googleapis.com/incruiter-media/audio_file_azure/audio-1722939778-117535.mp3',
                timeToPrepare: 10,
                timeToAnswer: 60,
                questionType: 'general',
                domain: 'Technical',
                question: 'Tell me about yourself?',
                questionAnswer: '',
            };
            break;
        case 2:
            questionSet = {
                response: `Thanks for your response.
Let's move on to the next topic of discussion.
Why are you interested in this position?
You have 10 seconds to begin your answer.
You can record your response after the beep sound.`,
                audioUrl: 'https://storage.googleapis.com/incruiter-media/audio_file_azure/audio-1723010317-117194.mp3',
                timeToPrepare: 10,
                timeToAnswer: 60,
                questionType: 'general',
                domain: 'Technical',
                question: 'Why are you interested in this position?',
                questionAnswer: '',
            };
            break;
        case 3:
            questionSet = {
                response: `Thanks for your response.
Let's move on to the next topic of discussion.
Describe your current job responsibilities.
You have 10 seconds to begin your answer.
You can record your response after the beep sound.`,
                audioUrl: 'https://storage.googleapis.com/incruiter-media/audio_file_azure/audio-1723010355-39097.mp3',
                timeToPrepare: 10,
                timeToAnswer: 60,
                questionType: 'general',
                domain: 'Technical',
                question: 'Describe your current job responsibilities.',
                questionAnswer: '',
            };
            break;
        case 4:
            questionSet = {
                response: `That's all from my side.
Thank you for your time and for sharing your experiences and insights with us.
Good luck! Your practice interview has been completed.
Please click on the SUBMIT button to end the practice interview.`,
                audioUrl: 'https://storage.googleapis.com/incruiter-media/audio_file_azure/audio-1723010418-98437.mp3',
                timeToPrepare: 10,
                timeToAnswer: 60,
                questionType: 'general',
                domain: 'Technical',
                question: `That's all from my side.
Thank you for your time and for sharing your experiences and insights with us.
Good luck! Your practice interview has been completed.
Please click on the SUBMIT button to end the practice interview.`,
                questionAnswer: '',
            };
            break;
        default:
            questionSet = {
                response: `Hello, I hope you’re doing well.
I'm glad to have you here today.
I appreciate you taking the time to interview with us.
I'm looking forward to learning more about you and your experiences.
So, let's get started.
Tell me about yourself?.
You have 10 seconds to begin your answer. You can record your response after the beep sound.`,
                audioUrl: 'https://storage.googleapis.com/incruiter-media/audio_file_azure/audio-1722939778-117535.mp3',
                timeToPrepare: 10,
                timeToAnswer: 60,
                questionType: 'general',
                domain: 'Technical',
                question: 'Tell me about yourself?',
                questionAnswer: '',
            };
            break;
    }

    return questionSet;
};

import { parsedUserAgent } from '@100mslive/react-sdk';

export const defaultAudioList = [
    {
        name: 'Audio1',
        id: 'audio1',
        metadata: {
            description: 'Artist1',
        },
        url: 'https://bc-public-static-assets.s3.ap-south-1.amazonaws.com/webapp/playlist/audio1.mp3',
        type: 'audio',
    },
];

export const defaultVideoList = [
    {
        name: 'Video1',
        id: 'video1',
        metadata: {
            description: 'Artist1',
        },
        url: 'https://bc-public-static-assets.s3.ap-south-1.amazonaws.com/webapp/playlist/video1.mp4',
        type: 'video',
    },
];

export const DEFAULT_HLS_ROLE_KEY = 'HLS_VIEWER_ROLE';
export const DEFAULT_HLS_VIEWER_ROLE = 'hls-viewer';
export const DEFAULT_WAITING_VIEWER_ROLE = 'waiting-room';
export const QUERY_PARAM_SKIP_PREVIEW = 'skip_preview';
export const QUERY_PARAM_SKIP_PREVIEW_HEADFUL = 'skip_preview_headful';
export const QUERY_PARAM_NAME = 'name';
export const QUERY_PARAM_VIEW_MODE = 'ui_mode';
export const QUERY_PARAM_AUTH_TOKEN = 'auth_token';
export const QUERY_PARAM_PREVIEW_AS_ROLE = 'preview_as_role';
export const UI_MODE_ACTIVE_SPEAKER = 'activespeaker';
export const UI_MODE_GRID = 'grid';
export const MAX_TOASTS = 5;
export const RTMP_RECORD_RESOLUTION_MIN = 480;
export const RTMP_RECORD_RESOLUTION_MAX = 1280;
export const RTMP_RECORD_DEFAULT_RESOLUTION = {
    width: 1280,
    height: 720,
};
export const EMOJI_REACTION_TYPE = 'EMOJI_REACTION';

export const CHAT_SELECTOR = {
    PEER_ID: 'peer_id',
    ROLE: 'role',
};

export const APP_DATA = {
    uiSettings: 'uiSettings',
    chatOpen: 'chatOpen',
    chatSelector: 'chatSelector',
    chatDraft: 'chatDraft',
    appConfig: 'appConfig',
    sidePane: 'sidePane',
    hlsStats: 'hlsStats',
    hlsViewerRole: 'hlsViewerRole',
    waitingViewerRole: 'waitingViewerRole',
    subscribedNotifications: 'subscribedNotifications',
    logo: 'logo',
    tokenEndpoint: 'tokenEndpoint',
    appLayout: 'appLayout',
    hlsStarted: 'hlsStarted',
    rtmpStarted: 'rtmpStarted',
    recordingStarted: 'recordingStarted',
    embedConfig: 'embedConfig',
    pdfConfig: 'pdfConfig',
    pinnedTrackId: 'pinnedTrackId',
    dropdownList: 'dropdownList',
    widgetState: 'widgetState',
};

export const UI_SETTINGS = {
    isAudioOnly: 'isAudioOnly',
    isHeadless: 'isHeadless',
    maxTileCount: 'maxTileCount',
    uiViewMode: 'uiViewMode',
    showStatsOnTiles: 'showStatsOnTiles',
    enableAmbientMusic: 'enableAmbientMusic',
    mirrorLocalVideo: 'mirrorLocalVideo',
    activeSpeakerSorting: 'activeSpeakerSorting',
    hideLocalVideo: 'hideLocalVideo',
};

export const WIDGET_STATE = {
    pollInView: 'pollInView',
    view: 'view',
};

export const WIDGET_VIEWS = {
    LANDING: 'LANDING',
    CREATE_POLL_QUIZ: 'CREATE_POLL_QUIZ',
    CREATE_QUESTIONS: 'CREATE_QUESTIONS',
    VOTE: 'VOTE',
    RESULTS: 'RESULTS',
};

export const SIDE_PANE_OPTIONS = {
    PARTICIPANTS: 'Participants',
    CHAT: 'Chat',
    STREAMING: 'STREAMING',
    TILES: 'TILES',
    SCREEN_TILES: 'SCREEN_TILES',
    WIDGET: 'WIDGET',
};

export const SUBSCRIBED_NOTIFICATIONS = {
    PEER_JOINED: 'PEER_JOINED',
    PEER_LEFT: 'PEER_LEFT',
    METADATA_UPDATED: 'METADATA_UPDATED',
    NEW_MESSAGE: 'NEW_MESSAGE',
    ERROR: 'ERROR',
};

export const CREATE_ROOM_DOC_URL = 'https://github.com/100mslive/100ms-web/wiki/Creating-and-joining-a-room';
export const HLS_TIMED_METADATA_DOC_URL =
    'https://www.100ms.live/docs/javascript/v2/how--to-guides/record-and-live-stream/hls/hls-timed-metadata';

export const REMOTE_STOP_SCREENSHARE_TYPE = 'REMOTE_STOP_SCREENSHARE';

export const isChrome = parsedUserAgent.getBrowser()?.name?.toLowerCase() === 'chrome';
export const isFirefox = parsedUserAgent.getBrowser()?.name?.toLowerCase() === 'firefox';
export const isSafari = parsedUserAgent.getBrowser()?.name?.toLowerCase() === 'safari';
export const isIOS = parsedUserAgent.getOS()?.name?.toLowerCase() === 'ios';
export const isMacOS = parsedUserAgent.getOS()?.name?.toLowerCase() === 'mac os';
export const isAndroid = parsedUserAgent.getOS()?.name?.toLowerCase() === 'android';
export const isIPadOS =
    navigator?.maxTouchPoints && navigator?.maxTouchPoints > 2 && navigator?.userAgent?.match(/Mac/);

export const FEATURE_LIST = {
    AUDIO_ONLY_SCREENSHARE: 'audioscreenshare',
    AUDIO_PLAYLIST: 'audioplaylist',
    VIDEO_PLAYLIST: 'videoplaylist',
    EMOJI_REACTION: 'emojireaction',
    AUDIO_PLUGINS: 'audioplugins',
    VIDEO_PLUGINS: 'videoplugins',
    WHITEBOARD: 'whiteboard',
    CHANGE_NAME: 'changename',
    FULLSCREEN: 'fullscreen',
    PICTURE_IN_PICTURE: 'pip',
    STARTS_FOR_NERDS: 'statsfornerds',
    EMBED_URL: 'embedurl',
    BRB: 'brb',
    HAND_RAISE: 'handraise',
    CHAT: 'chat',
    PIN_TILE: 'pintile',
};

export const SESSION_STORE_KEY = {
    TRANSCRIPTION_STATE: 'transcriptionState',
    PINNED_MESSAGE: 'pinnedMessage',
    SPOTLIGHT: 'spotlight',
};

export const INTERACTION_TYPE = {
    POLL: 'Poll',
    QUIZ: 'Quiz',
};

export const QUESTION_TYPE_TITLE = {
    'single-choice': 'Single Choice',
    'multiple-choice': 'Multiple Choice',
    // "short-answer": "Short Answer",
    // "long-answer": "Long Answer",
};

export const QUESTION_TYPE = {
    SINGLE_CHOICE: 'single-choice',
    MULTIPLE_CHOICE: 'multiple-choice',
    // SHORT_ANSWER: "short-answer",
    // LONG_ANSWER: "long-answer",
};

export const PDF_SHARING_OPTIONS = {
    FROM_YOUR_COMPUTER: 'From your Computer',
    FROM_A_URL: 'From a URL',
};

export const ASSET_NAMES = {
    BOT_GIF: 'botGif.gif',
    RECORDING_ICON: 'recordingIcon.svg',
    LOGO: 'logo.svg',
    INCRUITER_LOGO: 'incruiter_logo.svg',
    TECHNICAL_ERROR: 'technicalError.svg',
    HYPERLINK: 'hyperlink.svg',
    SKIP_FORWARD: 'skip-forward.svg',
    NEXT_QUESTION: 'next-question.svg',
    NOT_SUPPORTED: 'not-supported.svg',
    RUN_CODE: 'run-code.svg',
    SUBMIT_ICON: 'submit-icon.svg',
};

export const GENERAL_ERROR = 'Something went wrong';

export const AUDIO_NOT_DETECTED = 'Please ensure your microphone is working and read the text again.';

export const QUESTION_TYPES = {
    GLOBAL: 'global',
    CUSTOM: 'custom',
    CODING: 'coding',
    AI_GENERATED: 'ai-generated',
    MCQ: {
        LOGICAL: 'logical_reasoning',
        QUANTITATIVE: 'quantitative',
        VERBAL: 'verbal_reasoning',
        NUMBER: 'number_system',
    },
};

export const QUESTION = {
    QUESTION: 'question',
    EDITABLE_ANSWER: 'editableAnswer',
    ANSWER: 'answer',
    QUESTION_TYPE: 'questionType',
    TIME_TO_PREPARE: 'timeToPrepare',
    TIME_TO_ANSWER: 'timeToAnswer',
    IS_EDITABLE: 'isEditable',
    EDITABLE_QUESTION: 'editableQuestion',
    ERROR: 'error',
    DIFFICULTY_LEVEL: 'difficultyLevel',
    CODE_DURATION: 'codeDuration',
};

export const getCodeLanguages = () => [
    { value: 75, label: 'C (Clang 7.0.1)' },
    { value: 48, label: 'C (GCC 7.4.0)' },
    { value: 49, label: 'C (GCC 8.3.0)' },
    { value: 50, label: 'C (GCC 9.2.0)' },
    { value: 76, label: 'C++ (Clang 7.0.1)' },
    { value: 52, label: 'C++ (GCC 7.4.0)' },
    { value: 53, label: 'C++ (GCC 8.3.0)' },
    { value: 54, label: 'C++ (GCC 9.2.0)' },
    { value: 51, label: 'C# (Mono 6.6.0.161)' },
    { value: 90, label: 'Dart (2.19.2)' },
    { value: 91, label: 'Java (JDK 17.0.6)' },
    { value: 62, label: 'Java (OpenJDK 13.0.1)' },
    { value: 63, label: 'JavaScript (Node.js 12.14.0)' },
    { value: 93, label: 'JavaScript (Node.js 18.15.0)' },
    { value: 92, label: 'Python (3.11.2)' },
    { value: 71, label: 'Python (3.8.1)' },
    { value: 68, label: 'PHP (7.4.1)' },
    { value: 83, label: 'Swift (5.2.3)' },
    { value: 82, label: 'SQL (sqlite3 3.37.2)' },
];

export const codeLanguageIdMapping = {
    45: null,
    46: null,
    47: null,
    75: 'cpp',
    76: 'cpp',
    48: 'cpp',
    52: 'cpp',
    49: 'cpp',
    53: 'cpp',
    50: 'cpp',
    54: 'cpp',
    86: 'clojure',
    51: 'csharp',
    77: null,
    55: null,
    56: null,
    57: 'elixir',
    58: null,
    44: null,
    87: 'fsharp',
    59: null,
    60: 'go',
    88: null,
    61: null,
    62: 'java',
    63: 'javascript',
    78: 'kotlin',
    64: 'lua',
    89: null,
    79: 'objective-c',
    65: null,
    66: null,
    67: 'pascal',
    85: 'perl',
    68: 'php',
    43: null,
    69: null,
    70: 'python',
    71: 'python',
    80: 'r',
    72: 'ruby',
    73: 'rust',
    81: null,
    82: 'sql',
    83: 'swift',
    74: 'typescript',
    84: null,
    91: 'java',
    92: 'python',
    93: 'javascript',
    90: 'dart',
};

export const DEFAULT_FORM = {
    codeEditor: '',
    language: null,
    input: '',
    output: '',
    editorLang: {},
    prebuilt: '',
    preBuiltFunction: '',
    difficultyLevel: '',
    questionId: '',
    programmingLanguage: '',
};

export const DEFAULT_MCQ_FORM = {
    option_1: '',
    option_2: '',
    option_3: '',
    option_4: '',
    difficultyLevel: null,
};
export const MAX_TILES_FOR_MOBILE = 4;
export const CODE_EDITOR_LANGUAGES_MAP = {
    52: 'c++',
    53: 'c++',
    54: 'c++',
    76: 'c++',
    51: 'c#',
    48: 'c',
    49: 'c',
    50: 'c',
    75: 'c',
    90: 'dart',
    62: 'java',
    91: 'java',
    63: 'javascript',
    93: 'javascript',
    71: 'python',
    92: 'python',
    68: 'php',
    83: 'swift',
    78: 'sql',
};

export const MCQ_TYPE_TEXT = {
    logical_reasoning: 'Logical Reasoning',
    quantitative: 'Quantitative',
    number_system: 'Numerical Reasoning',
    verbal_reasoning: 'Verbal Reasoning',
};

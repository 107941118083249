import React, { useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { formatTime, getStatusV } from './components/Helpers';
import avatar from '../../assets/images/avatar.svg';
import MaleAvatar from '../../assets/images/MaleAvatar.svg';
import { MCQ_TYPE_TEXT, QUESTION_TYPES } from '../../common/constants';

export const ScreeningQuestionsV3 = ({ data, interview, setTotalAptitudeTime, totalAptitudeTime, version, image }) => {
    const getGeneralQuestions = data?.filter((question) => question.questionType === 'global');
    const getCustomQuestions = data?.filter(
        (question) =>
            question.questionType === QUESTION_TYPES.CUSTOM || question.questionType === QUESTION_TYPES.AI_GENERATED
    );
    const getCodingQuestions = data?.filter((question) => question.questionType === QUESTION_TYPES.CODING);
    const escapeHtml = (unsafe) => {
        return unsafe
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#039;');
    };

    const aptitude = interview?.question?.aptitude;
    const questions = interview?.questions;
    const questionsFeedback = interview?.feedback?.questions;

    questions?.forEach((q) => {
        const match = questionsFeedback?.find((fq) => fq.question === q.question);
        if (match) {
            q.rating = match?.rating;
            q.answer = match?.answer;
            q.questionSubmitTimestamp = match?.questionSubmitTimestamp;
        }
    });

    const updatedAptitude = aptitude?.map((apt) => {
        const matchedQuestions = questions.filter(
            (q) => q?.questionType === apt?.questionType && q?.difficultyLevel === apt?.difficultyLevel.toLowerCase()
        );
        const totalMarks = matchedQuestions.reduce((acc, q) => (q.rating ? acc + q.rating : acc + 0), 0);
        const lastQuestionTime =
            matchedQuestions?.length > 0 && matchedQuestions[matchedQuestions?.length - 1].questionSubmitTimestamp
                ? matchedQuestions[matchedQuestions?.length - 1].questionSubmitTimestamp
                : apt?.timeToAnswer;
        return {
            ...apt,
            totalMarks: totalMarks,
            lastQuestionTime: lastQuestionTime,
            responses: matchedQuestions,
        };
    });
    const addRadiusQuestion = version > 7 ? 'question-container question-container-radius' : 'question-container';

    useEffect(() => {
        const time = updatedAptitude.reduce((acc, apt) => {
            return acc + apt?.lastQuestionTime;
        }, 0);
        setTotalAptitudeTime(time || 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedAptitude]);

    return (
        <div className="screening-ques-wrapper break-stop">
            <div className="skills-div title_mob_hide">
                <span className="soft-skills-span">Screening Questions</span>
            </div>
            <div className="ques-ans-wrapper">
                <div className="screening_general_question soft-skills-span">General Questions</div>
                {getGeneralQuestions?.map((itm, index) => (
                    <>
                        <div className={`break-stop${itm?.conversationAIStatus ? ' con-back' : ''}`} key={index}>
                            <div className={addRadiusQuestion}>
                                <pre
                                    className="question-sub-panel screening_question_sub_panel"
                                    style={{ whiteSpace: 'pre-wrap', margin: '0px' }}
                                >
                                    <code
                                        dangerouslySetInnerHTML={{
                                            __html: `${index + 1}. ${itm?.question}`,
                                        }}
                                    />
                                </pre>
                                <div className="question-sub-panel time-taken">
                                    Time Taken:
                                    {itm?.conversationAIStatus
                                        ? formatTime(itm?.conversationRatingAndDuration?.timeTaken)
                                        : formatTime(itm?.timeTaken)}
                                </div>
                            </div>
                            <pre className="ans font-style-soft-skill" style={{ whiteSpace: 'pre-wrap' }}>
                                <p style={{ fontWeight: '600' }}>Transcript:- </p>
                                <code
                                    dangerouslySetInnerHTML={{
                                        __html: `${itm?.answer !== '' ? itm?.answer : "The candidate didn't answer."}`,
                                    }}
                                />
                            </pre>
                        </div>
                        <div className="conversation-container">
                            {itm?.conversationAIQuestions?.map((ques, index) => (
                                <div key={index} className="conversation font-style-soft-skill">
                                    <div>
                                        <div className="profile-img-div-conversation-left">
                                            <img className="profile-img-logo-conversation" src={avatar} alt="Logo" />
                                        </div>
                                        <div className="conversation-question">
                                            <strong>IncBot</strong>
                                            <p>{ques?.question}</p>
                                        </div>
                                    </div>
                                    <div style={{ position: 'relative' }}>
                                        <div className="conversation-transcript">
                                            <strong>Transcript</strong>
                                            <p>{ques?.answer || "The candidate didn't answer."}</p>
                                        </div>
                                        <div className="profile-img-div-conversation-right">
                                            <img
                                                className="profile-img-logo-conversation"
                                                src={image || MaleAvatar}
                                                alt="Logo"
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                ))}
            </div>
            {getCustomQuestions && getCustomQuestions.length > 0 && (
                <div className="ques-ans-wrapper">
                    <div className="screening_general_question soft-skills-span">Specific Questions</div>
                    {getCustomQuestions.map((itm, index) => (
                        <div className={`break-stop${itm?.conversationAIStatus ? ' con-back' : ''}`} key={index}>
                            <div className="break-stop">
                                <div className={addRadiusQuestion}>
                                    <pre
                                        className="question-sub-panel screening_question_sub_panel"
                                        style={{ whiteSpace: 'pre-wrap', margin: '0px' }}
                                    >
                                        {itm?.codeBlock ? (
                                            <div>
                                                {`${index + 1}. ${itm?.question}`}
                                                <div className="code-block-div">{itm?.codeBlock}</div>
                                            </div>
                                        ) : (
                                            <code
                                                dangerouslySetInnerHTML={{
                                                    __html: `${index + 1}. ${itm?.question}`,
                                                }}
                                            />
                                        )}
                                    </pre>
                                    {itm?.conversationAIStatus ? (
                                        <>
                                            <div className="question-sub-panel time-taken">
                                                Time Taken: {formatTime(itm?.conversationRatingAndDuration?.timeTaken)}
                                            </div>
                                            <div
                                                className={`graph-descp-comment graph-status question-sub-panel ${
                                                    getStatusV(itm?.conversationRatingAndDuration?.rating)?.color
                                                }`}
                                            >
                                                (
                                                {getStatusV(
                                                    itm?.conversationRatingAndDuration?.rating
                                                )?.title.substring(0, 21)}
                                                )
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="question-sub-panel time-taken">
                                                Time Taken: {formatTime(itm?.timeTaken)}
                                            </div>
                                            <div
                                                className={`graph-descp-comment graph-status question-sub-panel ${
                                                    getStatusV(itm?.rating)?.color
                                                }`}
                                            >
                                                ({getStatusV(itm?.rating)?.title.substring(0, 21)})
                                            </div>
                                        </>
                                    )}
                                </div>
                                <pre className="ans font-style-soft-skill" style={{ whiteSpace: 'pre-wrap' }}>
                                    <p style={{ fontWeight: '600' }}>Transcript:- </p>
                                    <code
                                        dangerouslySetInnerHTML={{
                                            __html: `${itm?.answer ? itm?.answer : "The candidate didn't answer."}`,
                                        }}
                                    />
                                </pre>
                                <pre
                                    className="ans font-style-soft-skill"
                                    style={{ whiteSpace: 'pre-wrap', padding: '0' }}
                                >
                                    <div className="coding-comment">
                                        <div className="coding-feedback-div">
                                            <div style={{ fontWeight: '600' }}>AI Comment:-</div>
                                        </div>
                                        <code
                                            dangerouslySetInnerHTML={{
                                                __html: `${itm?.ratingReason ? itm?.ratingReason : 'Not Available'}`,
                                            }}
                                        />
                                    </div>
                                </pre>
                            </div>
                            <div className="conversation-container">
                                {itm?.conversationAIQuestions?.map((ques, index) => (
                                    <div key={index} className="conversation font-style-soft-skill">
                                        <div>
                                            <div className="profile-img-div-conversation-left">
                                                <img
                                                    className="profile-img-logo-conversation"
                                                    src={avatar}
                                                    alt="Logo"
                                                />
                                            </div>
                                            <div className="conversation-question">
                                                <strong>IncBot</strong>
                                                <p>{ques?.question}</p>
                                            </div>
                                        </div>
                                        <div style={{ position: 'relative' }}>
                                            <div className="conversation-tech-div">
                                                <div className="conversation-transcript-tech">
                                                    <strong>Transcript</strong>
                                                    <p>{ques?.answer || "The candidate didn't answer."}</p>
                                                </div>
                                                <div className="conversation-ai-comment">
                                                    <strong>AI Comment</strong>
                                                    <p>{ques?.ratingReason || 'Not Available'}</p>
                                                </div>
                                            </div>
                                            <div className="profile-img-div-conversation-right">
                                                <img
                                                    className="profile-img-logo-conversation"
                                                    src={image || MaleAvatar}
                                                    alt="Logo"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {getCodingQuestions && getCodingQuestions.length > 0 && (
                <div className="ques-ans-wrapper">
                    <div className="screening_general_question soft-skills-span">Coding Questions</div>
                    {getCodingQuestions.map((itm, index) => (
                        <div className={`break-stop${itm?.conversationAIStatus ? ' con-back' : ''}`} key={index}>
                            <div className={addRadiusQuestion}>
                                <pre
                                    className="question-sub-panel screening_question_sub_panel"
                                    style={{ whiteSpace: 'pre-wrap', margin: '0px' }}
                                >
                                    {itm?.codeBlock ? (
                                        <div>
                                            {`${index + 1}. ${itm?.question}`}
                                            <div className="code-block-div">{itm?.codeBlock}</div>
                                        </div>
                                    ) : (
                                        <code
                                            dangerouslySetInnerHTML={{
                                                __html: `${index + 1}. ${itm?.question}`,
                                            }}
                                        />
                                    )}
                                </pre>
                                <div className="question-sub-panel time-taken">
                                    Time Taken: {formatTime(itm?.timeTaken)}
                                </div>
                                <div
                                    className={`graph-descp-comment graph-status question-sub-panel ${
                                        getStatusV(itm.rating)?.color
                                    }`}
                                >
                                    ({getStatusV(itm.rating)?.title.substring(0, 21)})
                                </div>
                            </div>
                            {itm?.questionType !== QUESTION_TYPES.CODING && (
                                <pre className="ans font-style-soft-skill" style={{ whiteSpace: 'pre-wrap' }}>
                                    <p style={{ fontWeight: '600' }}>Transcript:- </p>
                                    <code
                                        dangerouslySetInnerHTML={{
                                            __html: `${itm?.answer ? itm?.answer : "The candidate didn't answer."}`,
                                        }}
                                    />
                                </pre>
                            )}
                            <pre className="ans font-style-soft-skill" style={{ whiteSpace: 'pre-wrap', padding: '0' }}>
                                <div className="coding-comment">
                                    <div className="coding-feedback-div">
                                        <div style={{ fontWeight: '600' }}>
                                            AI Comment:- {itm?.language ? `Language(${itm?.language})` : ''}
                                        </div>
                                        {itm?.questionType === QUESTION_TYPES.CODING && (
                                            <div className="coding-feedback-container">
                                                {itm?.languageCode === 82 && (
                                                    <div className="coding-feedback">
                                                        <span className="coding-feedback-status">
                                                            Code Correctness:{' '}
                                                        </span>
                                                        <span
                                                            className={`coding-feedback-value ${
                                                                getStatusV(itm?.codeCorrectNess)?.color
                                                            }`}
                                                        >
                                                            {itm?.codeCorrectNess ?? 0}/10
                                                        </span>
                                                    </div>
                                                )}
                                                <div className="coding-feedback">
                                                    <span className="coding-feedback-status">Code Quality: </span>
                                                    <span
                                                        className={`coding-feedback-value ${
                                                            getStatusV(itm?.codeQuality)?.color
                                                        }`}
                                                    >
                                                        {itm?.codeQuality ?? 0}/10
                                                    </span>
                                                </div>
                                                <div className="coding-feedback">
                                                    <span className="coding-feedback-status">Code Optimisation: </span>
                                                    <span
                                                        className={`coding-feedback-value ${
                                                            getStatusV(itm?.codeOptimization)?.color
                                                        }`}
                                                    >
                                                        {itm?.codeOptimization ?? 0}/10
                                                    </span>
                                                </div>
                                                {itm?.totalTestCases ? (
                                                    <div className="coding-feedback">
                                                        <span className="coding-feedback-status">
                                                            Test Cases Passed:{' '}
                                                        </span>
                                                        <span className="coding-feedback-value coding-blue">
                                                            {itm?.testCasesPassed || 0}/{itm?.totalTestCases || 0}
                                                        </span>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}
                                                <div className="coding-feedback">
                                                    <span className="coding-feedback-status">
                                                        Overall Coding Score:{' '}
                                                    </span>
                                                    <span
                                                        className={`coding-feedback-value ${
                                                            getStatusV(itm?.rating)?.color
                                                        }`}
                                                    >
                                                        {itm?.rating ?? 0}/10
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <code
                                        dangerouslySetInnerHTML={{
                                            __html: `${itm?.ratingReason ? itm?.ratingReason : 'Not Available'}`,
                                        }}
                                    />
                                </div>
                            </pre>
                            {itm?.questionType === QUESTION_TYPES.CODING && (
                                <pre
                                    className="ans font-style-soft-skill coding-answer"
                                    style={{ whiteSpace: 'pre-wrap' }}
                                >
                                    <p style={{ fontWeight: '600' }}>Code Snippet:- </p>
                                    <code
                                        dangerouslySetInnerHTML={{
                                            __html: `${itm?.answer?.trim() ? escapeHtml(itm?.answer) : "The candidate didn't answer."}`,
                                        }}
                                    />
                                </pre>
                            )}
                        </div>
                    ))}
                </div>
            )}
            {updatedAptitude && updatedAptitude.length > 0 && (
                <div className="ques-ans-wrapper aptitude-section">
                    <div className="screening_general_question soft-skills-span">Aptitude</div>
                    <Accordion defaultActiveKey="0" className="aptitude-accordion">
                        {updatedAptitude.map((section, index) => (
                            <Accordion.Item
                                eventKey={index.toString()}
                                key={index}
                                className="aptitude-question-container"
                            >
                                <Accordion.Header>
                                    <div className="aptitude-question-content">
                                        <div className="aptitude-section-name">
                                            <span>{`Section: ${MCQ_TYPE_TEXT[section.questionType]}`}</span>
                                        </div>
                                        <div className="aptitude-data">
                                            <span>{`Difficulty Level: ${section?.difficultyLevel}`}</span>
                                        </div>
                                        <div className="aptitude-data">
                                            <span>{`Total Marks Scored: ${section.totalMarks}`}</span>
                                        </div>
                                        <div className="aptitude-data">
                                            <span>{`Time Taken: ${section.lastQuestionTime ? formatTime(section.lastQuestionTime) : formatTime(section.timeToAnswer)}`}</span>
                                        </div>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    {section?.responses?.map((itm, index) => (
                                        <div key={index}>
                                            <div className="question-container aptitude">
                                                <pre
                                                    className="question-sub-panel screening_question_sub_panel"
                                                    style={{ whiteSpace: 'pre-wrap', margin: '0px' }}
                                                >
                                                    <code
                                                        dangerouslySetInnerHTML={{
                                                            __html: `${index + 1}. ${itm?.question}`,
                                                        }}
                                                    />
                                                </pre>
                                                <div
                                                    className={`question-sub-panel ${itm?.answer === itm?.predefinedAnswer ? 'correct' : 'incorrect'}`}
                                                >
                                                    {itm?.answer === itm?.predefinedAnswer
                                                        ? '(Correct)'
                                                        : '(Incorrect)'}
                                                </div>
                                            </div>
                                            <pre
                                                className="ps-2 font-style-soft-skill d-flex gap-05"
                                                style={{ whiteSpace: 'pre-wrap' }}
                                            >
                                                <p style={{ fontWeight: '600' }}>User Input: </p>
                                                <p
                                                    className={`${itm?.answer === itm?.predefinedAnswer ? 'correct-ans-color' : 'coding-feedback-value'}`}
                                                >
                                                    {itm?.answer ? itm?.answer : 'Candidate did not answer'}
                                                </p>
                                                <p style={{ fontWeight: '600' }}>Correct Answer: </p>
                                                <p>{itm?.predefinedAnswer}</p>
                                            </pre>
                                        </div>
                                    ))}
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </div>
            )}
        </div>
    );
};
